import { useEffect, useState } from "react";
import "./step4.css";
import { FolderDropdown } from "../../Components";
import { BuildsList } from "../Step3/BuildsList/BuildsList";
import ErrorBoundaryPartRenderer from "../Step3/ErrorBoundary/ErrorBoundaryPartRenderer";
import Renderer from "../../ModelRenderer/Renderer";
import PartRenderer from "../../ModelRenderer/PartRenderer";
import { getObjectUrlFromS3 } from "../../AWS/awsService";
import { MdClose, MdFullscreen } from "react-icons/md";
import { trimPartName } from "../../Utils/utils";

//New imports
import ThreeJSRenderer from "../../ModelRenderer/PartRender.js";

//Library Imports
import { MdInfoOutline, MdOutline3DRotation } from "react-icons/md";
import { Tooltip as ReactTooltip } from "react-tooltip";

//Redux Imports
import { useDispatch, useSelector } from "react-redux";
import { setUploadingDisable } from "../../Store/Slice/stepperSlice";
import { setJobCost } from "../../Store/Slice/stepperSlice";

const Step4 = () => {
  //New
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [buildModels, setBuildModels] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [partModal, setShowPartModal] = useState(false);
  const [length, setLength] = useState(null);
  const [selectedPart, setSelectedPart] = useState(null);
  const [render, setRender] = useState(null);
  const [selectedBuild, setSelectedBuild] = useState(0); //initially null
  const [uniqueParts, setUniqueParts] = useState([]);
  const [totalJobCost, setTotalJobCost] = useState(0);
  const [width, setWidth] = useState(null);

  const dispatch = useDispatch();
  // const isDrawerOpen = useSelector(
  //   (state) => state.stepperSlice.drawer.isDrawerOpen,
  // );
  const builds = useSelector((state) => state.stepperSlice.job?.builds);
  const currencyType = useSelector((state) => state.authSlice.currencyType);
  const userID = useSelector((state) => state.authSlice.user?.id);
  const jobId = useSelector((state) => state.stepperSlice.job?.id);
  const view_click_status = useSelector((state) => state.stepperSlice.status);

  const formatCurrency = (value) => {
    //Converts string to number
    value = +value;

    //value multipliers according to currency value w.r.t INR
    switch (currencyType) {
      case "USD":
        return `${(value * 0.012).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}`;

      case "EUR":
        return `${(value * 0.011).toLocaleString("en-GB", {
          style: "currency",
          currency: "EUR",
        })}`;

      case "INR":
        return `${value.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        })}`;

      case "YEN":
        return `${(value * 1.77).toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY",
        })}`;

      // Add more cases for additional currency types if needed
      default:
        return value.toLocaleString();
    }
  };

  useEffect(() => {
    if (selectedPart !== null) {
      const mid = builds[selectedBuild]?.material_id;

      const path =
        `${userID}/job-${jobId}/material-${mid}/` + selectedPart.name;

      const fetchPartLink = async () => {
        const partLink = await getseUrlofPart(path);
        setRender(partLink);
        console.log("render Link -----", partLink);
      };

      fetchPartLink();
    }
  }, [selectedPart, builds, jobId, selectedBuild]);

  useEffect(() => {
    console.log("Running build Res");
    if (view_click_status === 1) {
      // dispatch(buildRes());
    }
  }, [dispatch]);

  const getseUrlofPart = async (path) => {
    return await getObjectUrlFromS3(path);
  };

  // THIS GIVES THE COST
  useEffect(() => {
    if (selectedBuild !== null) {
      // Fetch the parts for the selected build using parts id from the s3
      // setLoading(true);

      // Simulating an API call delay with setTimeout
      setTimeout(() => {
        // Mock data for parts
        const parts = builds[selectedBuild]?.parts_list || [];
        // Get unique part names and their counts
        const uniquePartsMap = parts.reduce((map, part) => {
          if (map[part.name]) {
            map[part.name].count += 1;
          } else {
            map[part.name] = {
              count: 1,
              cost: parseFloat(part.cost),
              material_cost: parseFloat(part.material_cost),
              printing_cost: parseFloat(part.printing_cost),
            };
          }
          return map;
        }, {});

        // Convert the unique parts map to an array of objects
        const uniquePartsArray = Object.keys(uniquePartsMap).map((name) => ({
          name,
          count: uniquePartsMap[name].count,
          quantity: uniquePartsMap[name].quantity,
          cost: uniquePartsMap[name].cost,
          material_cost: uniquePartsMap[name].material_cost,
          printing_cost: uniquePartsMap[name].printing_cost,
        }));

        // Calculate the sum of all build costs
        const totalCost = builds.reduce((total, build) => {
          const buildCost = parseFloat(build.build_cost);
          return total + buildCost;
        }, 0);

        // Set the unique parts and mark loading as complete
        setUniqueParts(uniquePartsArray);
        setTotalJobCost(totalCost);
        dispatch(setJobCost({ totalCost }));
        // setLoading(false);
      }, 200);
    }
  }, [selectedBuild]);

  function handleBuildClick(index) {
    setSelectedBuild(index);
    // alert(index);
  }
  // THIS GIVES BUILDS
  useEffect(() => {
    const fetchBuildData = async () => {
      const parts = builds[selectedBuild]?.parts_list || [];
      const length = builds[selectedBuild]?.base_plate_length;
      const width = builds[selectedBuild]?.base_plate_breadth;

      setLength(length);
      setWidth(width);

      const uniquePartsMap = parts.reduce((map, part) => {
        if (map[part.name]) {
          map[part.name].count += 1;
          map[part.name].material = part.material;
          map[part.name].id = part.id;
        } else {
          map[part.name] = {
            count: 1,
            material: part.material,
            id: part.id,
          };
        }

        return map;
      }, {});

      const uniquePartsArray = Object.keys(uniquePartsMap).map((name) => ({
        name,
        id: uniquePartsMap[name].id,
        count: uniquePartsMap[name].count,
        quantity: uniquePartsMap[name].quantity,
        material: uniquePartsMap[name].material,
      }));

      // setUniqueParts(uniquePartsArray);

      const partLinks = await Promise.all(
        parts.map(async (part) => {
          const path =
            `${userID}/job-${jobId}/material-${builds[selectedBuild]?.material_id}/` +
            part.name;
          const url = await getseUrlofPart(path);

          return url;
        }),
      );

      const models = partLinks.map((link, index) => ({
        link,
        coordinates: parts[index].coordinates,
        orientation: parts[index].orientation,
      }));

      setBuildModels(models);
    };

    if (selectedBuild !== null && builds) {
      fetchBuildData();
    }

    builds?.length
      ? dispatch(setUploadingDisable(false))
      : dispatch(setUploadingDisable(true));
  }, [selectedBuild, builds, jobId]);

  function handleModalToggle(index) {
    setSelectedBuild(index);
    setShowModal(!showModal);
  }

  async function handlePartModalToggle(part) {
    setSelectedPart(part);
    setShowPartModal(!partModal);
  }

  //Full Screen

  //Full Screen
  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    const renderer = document.querySelector(".threejs-main-container canvas");
    if (renderer) {
      if (!isFullScreen) {
        renderer.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    }
  };

  return (
    <div className="step3-container">
      <div className="step1-left-container">
        <FolderDropdown />
      </div>

      <div className="step4-right-container">
        <div className="step4-builds-container">
          <div className="build-list-container step4">
            <div
              className="build-list-header"
              style={{ height: "auto", marginBottom: "0px" }}
            >
              <h2
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize: "1rem",
                  padding: "3px",
                }}
              >
                Builds
                <MdInfoOutline
                  color="black"
                  data-tooltip-id="builds-target"
                  data-tooltip-content="Builds are parts which gets assigned to a particular machine. 
								Multiple builds could be available from same machine. "
                  data-tooltip-delay-show="150"
                />
                <ReactTooltip
                  id="builds-target"
                  place="right"
                  style={{
                    width: "25rem",
                    fontWeight: "500",
                    fontSize: "medium",
                    zIndex: "2",
                  }}
                />
              </h2>
            </div>

            <div
              className="builds-list"
              style={{
                height: "69vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {/* {builds ? (
								builds.map((build, index) => (
									<div
										className={`builds ${
											selectedBuild === index ? "active" : ""
										}`}
										key={index}
									>
										<div>
											<button onClick={() => handleBuildClick(index)}>
												<span>{`Build ${index}`}</span>
												<br />
												<span style={{ fontSize: "x-small" }}>
													{build.parts_list[0].material}
												</span>
											</button>
										</div>
									
									</div>
								))
							) : (
								<div
									style={{
										display: "flex",
										alignItems: "cneter",
										justifyContent: "center",
									}}
								>
									Loading...
								</div> */}
              {/* )} */}

              <div style={{ height: "60vh", overflow: "auto" }}>
                <BuildsList
                  handleBuildClick={handleBuildClick}
                  selectedBuild={selectedBuild}
                  builds={builds}
                  handleModalToggle={handleModalToggle}
                />
              </div>

              <div className="total-build-time">
                <div>
                  <h3>Total Build Time</h3>
                </div>

                <div className="step4-total-cst">
                  <h3>{builds[selectedBuild]?.build_time} hrs</h3>
                </div>
              </div>
            </div>

            {showModal && (
              <Renderer
                models={buildModels}
                length={length}
                width={width}
                onClose={() => setShowModal(false)}
              />
            )}

            {partModal && (
              <ErrorBoundaryPartRenderer
                onClose={() => setShowPartModal(false)}
              >
                {/* <PartRenderer
									name={render}
									partName={selectedPart.name}
									onClose={handlePartModalToggle}
								/> */}

                <div className="modal">
                  <div className="threejs-main-container">
                    <div
                      style={{
                        position: "absolute",
                        top: "0.6rem",
                        left: "1rem",
                        color: "gray",
                        display: "flex",
                        fontWeight: "bolder",
                      }}
                    >
                      <p>
                        Part Name: <span>{selectedPart.name}</span>
                      </p>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        right: "0.6rem",
                        top: "0.6rem",
                      }}
                      onClick={handlePartModalToggle}
                    >
                      <MdClose size={25} fill="black" />
                    </div>

                    <div
                      style={{
                        zIndex: "1000",
                        position: "absolute",
                        right: "3rem",
                        top: "0.6rem",
                        color: "white",
                      }}
                      onClick={handleFullScreen}
                    >
                      <MdFullscreen size={25} fill="black" />
                    </div>

                    <ThreeJSRenderer
                      name={render}
                      // partName={selectedPart.name}
                      // onClose={handlePartModalToggle}
                    />

                    <div
                      style={{
                        position: "absolute",
                        bottom: "0.6rem",
                        right: "1rem",
                        color: "gray",
                      }}
                    >
                      <p>Powered by Intech Additive Solutions Pvt Ltd</p>
                    </div>
                  </div>
                </div>
              </ErrorBoundaryPartRenderer>
            )}
          </div>

          <div className="part-list-container" style={{ fontSize: "small" }}>
            <div className="part-list-header" style={{ height: "auto" }}>
              <h2>Parts</h2>
            </div>

            {!builds?.length ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Loading parts...
              </div>
            ) : (
              <div className="part-list-container">
                <div className="part-header" style={{ height: "auto" }}>
                  <div
                    className="part-cell"
                    style={{
                      backgroundColor: "lightgrey",
                      borderBottom: "2px solid black",
                    }}
                  >
                    <h3>Part Name</h3>
                  </div>

                  <div
                    className="part-cell"
                    style={{
                      backgroundColor: "lightgrey",
                      borderBottom: "2px solid black",
                    }}
                  >
                    <h3> Quantity</h3>
                  </div>

                  <div
                    className="part-cell"
                    style={{
                      backgroundColor: "lightgrey",
                      borderBottom: "2px solid black",
                    }}
                  >
                    <h3> Unit Cost</h3>
                  </div>

                  <div
                    className="part-cell"
                    style={{
                      backgroundColor: "lightgrey",
                      borderBottom: "2px solid black",
                    }}
                  >
                    <h3> Material Cost</h3>
                  </div>

                  <div
                    className="part-cell"
                    style={{
                      backgroundColor: "lightgrey",
                      borderBottom: "2px solid black",
                    }}
                  >
                    <h3> Printing Cost</h3>
                  </div>

                  <div
                    className="part-cell"
                    style={{
                      backgroundColor: "lightgrey",
                      borderBottom: "2px solid black",
                    }}
                  >
                    <h3> Total Cost</h3>
                  </div>
                </div>

                <div className="part-list step4">
                  <div className="step4-list-scroll">
                    {selectedBuild !== null &&
                      builds &&
                      builds[selectedBuild] &&
                      uniqueParts.map((part, index) => (
                        <div className="part-row" key={index}>
                          <div className="part-cell">
                            {trimPartName(part.name)}
                          </div>

                          {/* Quantity */}
                          <div
                            className="part-cell"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {part.count}
                            <span>
                              <button
                                className="view-btn"
                                onClick={() => handlePartModalToggle(part)}
                              >
                                <MdOutline3DRotation /> View
                              </button>
                            </span>
                          </div>

                          {/* Part Cost */}
                          <div
                            className="part-cell"
                            style={{
                              color: "#60ad57",
                              backgroundColor: "#E6FFEC",
                            }}
                          >
                            <h3>{formatCurrency(part?.cost?.toFixed(2))} </h3>
                          </div>

                          {/* Material Cost */}
                          <div
                            className="part-cell"
                            style={{
                              color: "#60ad57",
                              backgroundColor: "#E6FFEC",
                            }}
                          >
                            <h3>
                              {formatCurrency(part?.material_cost?.toFixed(2))}{" "}
                            </h3>
                          </div>

                          {/* Printing Cost */}
                          <div
                            className="part-cell"
                            style={{
                              color: "#60ad57",
                              backgroundColor: "#E6FFEC",
                            }}
                          >
                            <h3>
                              {formatCurrency(part.printing_cost.toFixed(2))}{" "}
                            </h3>
                          </div>

                          {/* Total Cost */}
                          <div
                            className="part-cell"
                            style={{
                              color: "#ff6200",
                              backgroundColor: "#ffffe0",
                            }}
                          >
                            <h3
                              style={{
                                fontWeight: "900",
                              }}
                            >
                              {formatCurrency(
                                (part?.count * part?.cost).toFixed(2),
                              )}{" "}
                            </h3>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      padding: "10px",
                      borderTop: "1px solid lightgrey",
                    }}
                  >
                    <div className="total-job-cost">
                      <div>
                        <h3>Total Job Cost -</h3>
                      </div>

                      <div className="step4-total-job-cst">
                        <h3>
                          {formatCurrency(parseFloat(totalJobCost).toFixed(2))}
                        </h3>
                      </div>
                    </div>

                    <div className="total-job-cost">
                      <div>
                        <h3>Total Build Cost -</h3>
                      </div>

                      <div className="step4-total-cst">
                        <h3>
                          {formatCurrency(
                            parseFloat(
                              builds[selectedBuild]?.build_cost,
                            ).toFixed(2),
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step4;
