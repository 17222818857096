import styles from "./loadingDots.module.css";

const LoadingDots = () => {
  return (
    <div className={styles.loadingDots}>
      <div className={styles.loadingDot}></div>
      <div className={styles.loadingDot}></div>
      <div className={styles.loadingDot}></div>
    </div>
  );
};

export { LoadingDots };
