import Mhr from "./Mhr/Mhr.js";
import MachineType from "./MachineType/MachineType.js";
import { useEffect, useState } from "react";
import "../settings.css";

//Library Imports
import { MdAdd } from "react-icons/md";
//Redux Imports
import { useSelector, useDispatch } from "react-redux";
import { addCurrency } from "../../Store/Slice/authSlice.js";
import {
	openModal,
	currencyConversion,
} from "../../Store/Slice/settingsSlice.js";

const currencyOptions = [
	{ value: "USD", label: "USD" },
	{ value: "EUR", label: "EUR" },
	{ value: "INR", label: "INR" },
	{ value: "YEN", label: "YEN" },
	// Add more currency options as needed
];

const SettingsPage = () => {
	// State to manage the active tab
	const [activeTab, setActiveTab] = useState("machine"); // "machine" for MachineTable, "machineType" for MachineType
	// Logic to handle tab switching
	const handleTabChange = (tab) => {
		setActiveTab(tab);
	};

	const handleCurrency = (e) => {
		dispatch(addCurrency(e.target.value));
	};

	const handleOpenModal = () => {
		dispatch(openModal());
	};

	//Redux imports
	const dispatch = useDispatch();
	const currencyType = useSelector((state) => state.authSlice.currencyType);
	const settingsCurrencyType = useSelector(
		(state) => state.settingsSlice.currencyType
	);

	useEffect(() => {
		console.log(currencyType, "Auth Currency Type");
		console.log(settingsCurrencyType, "Settings Currency Type");

		if (currencyType !== settingsCurrencyType) {
			dispatch(
				currencyConversion({
					settingsCurrencyType,
					authCurrencyType: currencyType,
				})
			);
		}
	}, [currencyType]);

	return (
		<div className="settings-container">
			<h1>Settings</h1>
			<div className="top-btn-container">
				<div className="currency-container">
					<label>
						<b>Currency Type:</b>
					</label>
					<select
						className="select-currency"
						name="currencyType"
						value={currencyType}
						onChange={handleCurrency}
						style={{
							width: "10%",
							marginLeft: "8px",
							marginTop: "-7px",
						}}
					>
						{currencyOptions.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
				</div>
				{/* <button className="add-machine-button" onClick={handleOpenModal}>
					<MdAdd size={20} />
					Add New Machine
				</button> */}
			</div>

			{/* Tab navigation */}
			<div className="tab-navigation">
				<div
					className={`tab ${activeTab === "machine" ? "active" : ""}`}
					onClick={() => handleTabChange("machine")}
				>
					Machines
				</div>
				<div
					className={`tab ${activeTab === "machineType" ? "active" : ""}`}
					onClick={() => handleTabChange("machineType")}
				>
					MHR Calcuator
				</div>
			</div>
			{/* Render the appropriate tab based on the activeTab state */}
			{activeTab === "machine" && <MachineType />}
			{activeTab === "machineType" && <Mhr />}
		</div>
	);
};

export default SettingsPage;
