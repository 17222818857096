import { useDispatch } from "react-redux";
import { MdDelete } from "react-icons/md";
import "./step2.css";
import { useSelector } from "react-redux";
import { addQuantity, deletePart } from "../../Store/Slice/stepperSlice";
import { deleteSingleFileFromS3 } from "../../AWS/awsService";
import { FolderDropdown } from "../../Components";
import { trimPartName } from "../../Utils/utils";

const Step2 = () => {
	const materials = useSelector((state) => state.stepperSlice.job.materials);
	const jobid = useSelector((state) => state.stepperSlice?.job?.id);
	const userID = useSelector((state) => state.authSlice?.user?.id);

	const dispatch = useDispatch();

	const handleQuantityChange = (event, materialIndex, partIndex) => {
		console.log("new valu mat - part", materialIndex, partIndex);
		dispatch(
			addQuantity({
				materialIndex,
				partIndex,
				quantity: Number(event.target.value),
			})
		);
	};

	const quantityOptions = [];
	for (let i = 1; i <= 10; i++) {
		quantityOptions.push(<option value={i}>{i}</option>);
	}

	// const handleFolderToggle = () => {
	// 	dispatch(setFolderOpen(!folderOpen));
	// };
	// const trimPartName = (partName) => {
	// 	if (partName.length <= 10) {
	// 		return partName;
	// 	}

	// 	const ellipsis = "...";
	// 	const nameWithoutExtension = partName.split(".")[0];
	// 	const extension = partName.split(".").slice(1).join(".");
	// 	const trimmedName = nameWithoutExtension.substring(0, 10 - 3);

	// 	return trimmedName + ellipsis + extension;
	// };

	return (
		<div className="step2-container">
			<div className="step1-left-container">
				<FolderDropdown />
			</div>
			<div className="step2-right-container">
				<div className="step2-card-container">
					<div className="part-list-header">
						<h2>List Of Parts</h2>
					</div>

					<table className="step2-table">
						<thead>
							<tr>
								<th>Part Name</th>
								<th>Material Name</th>
								<th>Quantity</th>
							</tr>
						</thead>
						<tbody>
							{materials?.map(
								(material, materialIndex) =>
									material.parts?.map((part, partindex) => (
										<tr key={part.id}>
											<td>{trimPartName(part.name)}</td>
											<td
												style={{
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
												}}
											>
												{material.material}
											</td>
											<td
												style={{
													marginRight: "10px",
													display: "flex",
													gap: "2rem",
												}}
											>
												<select
													value={part.qty}
													onChange={(event) =>
														handleQuantityChange(
															event,
															materialIndex,
															partindex
														)
													}
												>
													{quantityOptions}
												</select>
												<MdDelete
													size={30}
													onClick={() => {
														deleteSingleFileFromS3(
															jobid,
															material.id,
															part.name,
															userID
														);
														dispatch(
															deletePart({
																partId: part.id,
																materialId: material.id,
															})
														);
													}}
												/>
											</td>
											{/* <td>
                        
                      </td> */}
										</tr>
									))
								// end
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default Step2;
