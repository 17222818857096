import AWS from "aws-sdk";

const S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET_NAME;
const REGION = process.env.REACT_APP_AWS_REGION;

AWS.config.update({
	accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
	secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
	params: { Bucket: S3_BUCKET },
	region: REGION,
});

export const uploadFileToS3 = (
	file,
	jobId,
	userCardId,
	setProgress,
	userID
) => {
	return new Promise((resolve, reject) => {
		const params = {
			ACL: "public-read",
			Body: file,
			Bucket: S3_BUCKET,
			Key: `${userID}/job-${jobId}/material-${userCardId}/` + file.name,
		};
		myBucket
			.putObject(params)
			.on("httpUploadProgress", (evt) => {
				setProgress(Math.round((evt.loaded / evt.total) * 100));
			})
			.send((err) => {
				if (err) {
					reject(err);
					console.log(err);
				} else {
					const urlParams = { Bucket: S3_BUCKET, Key: params.Key };
					const s3Url = myBucket.getSignedUrl("getObject", urlParams);
					resolve(s3Url);
				}
			});
	});
};

export const multiPartUploadToS3 = (
	file,
	jobId,
	userCardId,
	setProgress,
	userID
) => {
	return new Promise((resolve, reject) => {
		const params = {
			Bucket: S3_BUCKET,
			Key: `${userID}/job-${jobId}/material-${userCardId}/` + file.name,
		};

		myBucket.createMultipartUpload(params, (err, data) => {
			if (err) {
				reject("Error creating multipart upload");
				console.error("Error creating multipart upload:", err);
				return;
			}

			const { UploadId } = data;
			const partSize = 5 * 1024 * 1024; // 5MB
			const parts = Math.ceil(file.size / partSize);
			const uploadedParts = [];
			const progressArray = [];

			const uploadPart = (partNumber, start, end) => {
				const uploadPartParams = {
					...params,
					UploadId,
					PartNumber: partNumber,
					Body: file.slice(start, end),
				};

				myBucket
					.uploadPart(uploadPartParams, (err, data) => {
						if (err) {
							reject("Error uploading part:", err);
							console.error("Error uploading part:", err);
							return;
						}

						uploadedParts.push({
							ETag: data.ETag,
							PartNumber: partNumber,
						});

						if (uploadedParts.length === parts) {
							completeMultipartUpload();
						}
					})
					.on("httpUploadProgress", ({ loaded, total }) => {
						const partProgress = Math.floor((loaded / total) * 100);
						progressArray[partNumber] = partProgress;
						setProgress(
							(
								progressArray.reduce((prev, curr) => (prev += curr), 0) / parts
							).toFixed(1)
						);
					});
			};

			for (let i = 0; i < parts; i++) {
				const start = i * partSize;
				const end = Math.min(start + partSize, file.size);
				uploadPart(i + 1, start, end);
			}

			const completeMultipartUpload = () => {
				uploadedParts.sort((a, b) => a.PartNumber - b.PartNumber);
				const paramsMultiplePartsUpload = {
					...params,
					UploadId,
					MultipartUpload: {
						Parts: uploadedParts,
					},
				};

				myBucket.completeMultipartUpload(
					paramsMultiplePartsUpload,
					(err, data) => {
						if (err) {
							reject("Unable to complete upload", err);
							console.error("Unable to complete upload", err);
							return;
						}

						const urlParams = { Bucket: S3_BUCKET, Key: params.Key };
						const s3Url = myBucket.getSignedUrl("getObject", urlParams);
						resolve(s3Url);
					}
				);
			};
		});
	});
};

export const deleteFilesFromS3 = async (jobId, userCardId, userID) => {
	const params = {
		Bucket: S3_BUCKET,
		Prefix: `${userID}/job-${jobId}/material-${userCardId}/`,
	};

	const response = await myBucket.listObjectsV2(params).promise();
	const objectsToDelete = response.Contents.map((object) => ({
		Key: object.Key,
	}));

	if (objectsToDelete.length > 0) {
		const deleteParams = {
			Bucket: S3_BUCKET,
			Delete: {
				Objects: objectsToDelete,
				Quiet: false,
			},
		};

		await myBucket.deleteObjects(deleteParams).promise();
		console.log("Files deleted from S3");
	}
};

const deleteJobFromS3 = async (jobId, userID) => {
	try {
		const params = {
			Bucket: S3_BUCKET, //S3 bucket name
			Prefix: `${userID}/job-${jobId}/`, // Path to the job folder in S3
		};

		const data = await myBucket.listObjectsV2(params).promise();

		if (data.Contents.length === 0) {
			console.log(`No objects found in the job folder: ${params.Prefix}`);
			return;
		}

		const deleteParams = {
			Bucket: S3_BUCKET,
			Delete: {
				Objects: data.Contents.map(({ Key }) => ({ Key })),
				Quiet: false,
			},
		};

		data.Contents.forEach((content) => {
			deleteParams.Delete.Objects.push({ Key: content.Key });
		});

		await myBucket.deleteObjects(deleteParams).promise();

		console.log(`Job folder deleted: ${params.Prefix}`);
	} catch (error) {
		console.error("Error deleting job folder from S3:", error);
	}
};

const deleteSingleFileFromS3 = async (jobId, userCardId, fileName, userID) => {
	const params = {
		Bucket: S3_BUCKET,
		Key: `${userID}/job-${jobId}/material-${userCardId}/${fileName}`,
	};

	try {
		await myBucket.deleteObject(params).promise();
		console.log(`File deleted from S3: ${params.Key}`);
	} catch (error) {
		console.error("Error deleting file from S3:", error);
	}
};

export { deleteJobFromS3, deleteSingleFileFromS3 };

// import AWS from "aws-sdk";
// import {
//   CreateMultipartUploadCommand,
//   UploadPartCommand,
//   CompleteMultipartUploadCommand,
//   AbortMultipartUploadCommand,
//   S3Client,
// } from "@aws-sdk/client-s3";

// const S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET_NAME;
// const REGION = process.env.REACT_APP_AWS_REGION;

// AWS.config.update({
//   accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
//   secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
// });

// const myBucket = new AWS.S3({
//   params: { Bucket: S3_BUCKET },
//   region: REGION,
// });

// const createString = (size = 25 * 1024 * 1024) => {
//   return "x".repeat(size);
// };

// const fileToBlob = (file) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onloadend = () => {
//       resolve(reader.result);
//     };
//     reader.onerror = reject;
//     reader.readAsArrayBuffer(file);
//   });
// };

// export const uploadFileToS3 = async (file, jobId, userCardId, setProgress) => {
//   const s3Client = new S3Client({ region: REGION });

//   const key = `userId/job-${jobId}/material-${userCardId}/` + file.name;
//   const blob = await fileToBlob(file);

//   let uploadId;

//   try {
//     const multipartUpload = await s3Client.send(
//       new CreateMultipartUploadCommand({
//         Bucket: S3_BUCKET,
//         Key: key,
//       })
//     );

//     uploadId = multipartUpload.UploadId;

//     const uploadPromises = [];
//     const partSize = Math.ceil(blob.size / 5);

//     for (let i = 0; i < 5; i++) {
//       const start = i * partSize;
//       const end = Math.min(start + partSize, blob.size);
//       const partData = blob.slice(start, end);
//       uploadPromises.push(
//         s3Client
//           .send(
//             new UploadPartCommand({
//               Bucket: S3_BUCKET,
//               Key: key,
//               UploadId: uploadId,
//               Body: partData,
//               PartNumber: i + 1,
//             })
//           )
//           .then((data) => {
//             console.log("Part", i + 1, "uploaded");
//             return data;
//           })
//       );
//     }

//     const uploadResults = await Promise.all(uploadPromises);

//     await s3Client.send(
//       new CompleteMultipartUploadCommand({
//         Bucket: S3_BUCKET,
//         Key: key,
//         UploadId: uploadId,
//         MultipartUpload: {
//           Parts: uploadResults.map(({ ETag }, i) => ({
//             ETag,
//             PartNumber: i + 1,
//           })),
//         },
//       })
//     );

//     const urlParams = { Bucket: S3_BUCKET, Key: key };
//     const s3Url = myBucket.getSignedUrl("getObject", urlParams);
//     return s3Url;
//   } catch (err) {
//     console.error(err);

//     if (uploadId) {
//       await s3Client.send(
//         new AbortMultipartUploadCommand({
//           Bucket: S3_BUCKET,
//           Key: key,
//           UploadId: uploadId,
//         })
//       );
//     }

//     throw err;
//   }
// };

// // The remaining code remains the same

// export const deleteFilesFromS3 = async (jobId, userCardId) => {
//   const params = {
//     Bucket: S3_BUCKET,
//     Prefix: `userId/job-${jobId}/material-${userCardId}/`,
//   };

//   const response = await myBucket.listObjectsV2(params).promise();
//   const objectsToDelete = response.Contents.map((object) => ({
//     Key: object.Key,
//   }));

//   if (objectsToDelete.length > 0) {
//     const deleteParams = {
//       Bucket: S3_BUCKET,
//       Delete: {
//         Objects: objectsToDelete,
//         Quiet: false,
//       },
//     };

//     await myBucket.deleteObjects(deleteParams).promise();
//     console.log("Files deleted from S3");
//   }
// };

// const deleteJobFromS3 = async (jobId) => {
//   try {
//     const s3 = new AWS.S3();
//     const params = {
//       Bucket: S3_BUCKET, //S3 bucket name
//       Prefix: `userId/job-${jobId}/`, // Path to the job folder in S3
//     };

//     const data = await s3.listObjectsV2(params).promise();

//     if (data.Contents.length === 0) {
//       console.log(`No objects found in the job folder: ${params.Prefix}`);
//       return;
//     }

//     const deleteParams = {
//       Bucket: params.Bucket,
//       Delete: { Objects: [] },
//     };

//     data.Contents.forEach((content) => {
//       deleteParams.Delete.Objects.push({ Key: content.Key });
//     });

//     await s3.deleteObjects(deleteParams).promise();

//     console.log(`Job folder deleted: ${params.Prefix}`);
//   } catch (error) {
//     console.error("Error deleting job folder from S3:", error);
//   }
// };

// const deleteSingleFileFromS3 = async (jobId, userCardId, fileName) => {
//   const params = {
//     Bucket: S3_BUCKET,
//     Key: `userId/job-${jobId}/material-${userCardId}/${fileName}`,
//   };

//   try {
//     await myBucket.deleteObject(params).promise();
//     console.log(`File deleted from S3: ${params.Key}`);
//   } catch (error) {
//     console.error("Error deleting file from S3:", error);
//   }
// };

// export { deleteJobFromS3, deleteSingleFileFromS3 };
export const getObjectUrlFromS3 = async (filePath) => {
	const params = {
		Bucket: S3_BUCKET,
		Key: filePath,
	};

	try {
		const signedUrl = await myBucket.getSignedUrlPromise("getObject", params);
		// console.log("got the link - ", signedUrl);
		// console.log("filepath-", filePath);
		return signedUrl;
	} catch (error) {
		console.error("Error fetching object URL from S3:", error);
		return null;
	}
};

// my link - filepath- userId/job-e6e24255-de15-47cb-a46c-5758174759ab/material-855ef5fa-cd47-4857-b10c-bfcf01c91dba/Step (1).stl
//s3 link -  filepath- userId/job-e6e24255-de15-47cb-a46c-5758174759ab/material-0aeeb797-01ec-4122-977c-fcf99ddc88f2/Step (1).stl
// aeaf675c-219d-4678-88ea-735949a6295e
// aeaf675c-219d-4678-88ea-735949a6295e/Step (1).stl
