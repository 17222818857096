import "./renderer.css";
import { Canvas, useFrame, useLoader, useThree } from "react-three-fiber";
import { Suspense, useEffect, useRef, useState } from "react";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { BufferGeometry, TextureLoader } from "three";
import { MdClose } from "react-icons/md";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { FaMousePointer } from "react-icons/fa";

import { MdBuild, MdRefresh, MdLayers } from "react-icons/md";
import Loader from "./ModelLodader";
import { LineBasicMaterial, Vector3 } from "three";
import * as THREE from "three";

//AXIS INDICATOR
const Axes = (props) => {
	const lineMaterialX = new LineBasicMaterial({ color: "red" });
	const lineMaterialY = new LineBasicMaterial({ color: "green" });
	const lineMaterialZ = new LineBasicMaterial({ color: "blue" });

	const origin = new Vector3(0, 0, 0);
	const length = props.length / 2;

	const xEndPositive = new Vector3(length, 0, 0);
	const xEndNegative = new Vector3(-length, 0, 0);
	const yEndPositive = new Vector3(0, length, 0);
	const yEndNegative = new Vector3(0, -length, 0);
	const zEndPositive = new Vector3(0, 0, length);
	const zEndNegative = new Vector3(0, 0, -length);

	return (
		<>
			<line
				geometry={new BufferGeometry().setFromPoints([origin, xEndPositive])}
				material={lineMaterialX}
			/>
			<line
				geometry={new BufferGeometry().setFromPoints([origin, xEndNegative])}
				material={lineMaterialX}
			/>
			{/* <line
          geometry={new BufferGeometry().setFromPoints([origin, yEndPositive])}
          material={lineMaterialY}
        />
        <line
          geometry={new BufferGeometry().setFromPoints([origin, yEndNegative])}
          material={lineMaterialY}
        /> */}
			<line
				geometry={new BufferGeometry().setFromPoints([origin, zEndPositive])}
				material={lineMaterialZ}
			/>
			<line
				geometry={new BufferGeometry().setFromPoints([origin, zEndNegative])}
				material={lineMaterialZ}
			/>
		</>
	);
};

// CAMERA CONTROLS
const CameraController = () => {
	const { camera, gl } = useThree();
	useEffect(() => {
		const controls = new OrbitControls(camera, gl.domElement);
		controls.minDistance = 80;
		controls.maxDistance = 900;
		// camera.lookAt(0, 0, 0);
		// camera.up.set(0, 0, 1);
		return () => {
			controls.dispose();
		};
	}, [camera, gl]);
	return null;
};

//USER RENDER GEOMETRY
const Geom = (props) => {
	const meshref = useRef();
	const [bb, setbb] = useState(null);
	const stl = useLoader(STLLoader, `${props.name}`, (geometry) => {});

	const boundingBoxRef = useRef(new THREE.Box3());
	const newbbox = CalculateBoundingBox(stl);
	console.log("new bounding box - ", newbbox);
	useEffect(() => {
		// Update bounding box when the orientation changes
		boundingBoxRef.current.setFromObject(meshref.current);
		console.log("meshref bounding box--", meshref.current);
		// setbb(meshref.current.geometry.boundingBox);
		console.log("meshref-bbox-refrence--", boundingBoxRef);
		setbb(boundingBoxRef.current);
	}, [props.stl]);
	stl.center();
	const texture = useLoader(TextureLoader, `/wood.jpg`);
	const x = new THREE.Vector3(1, 0, 0);
	const y = new THREE.Vector3(0, 1, 0);
	const z = new THREE.Vector3(0, 0, 1);

	const material = new THREE.MeshStandardMaterial({
		// color: "#818181",
		color: "rgb(54,55,55)",
		wireframe: false,
	});

	material.side = THREE.DoubleSide;

	const mesh = new THREE.Mesh(
		stl,
		material
		// new THREE.MeshStandardMaterial({ color: "#818181", wireframe: false }),
	);
	// mesh.position.set(...alignToPlate(props.position, bb));
	mesh.castShadow = true;

	// mesh.rotateOnWorldAxis(x, props.rotation[0]);
	// mesh.rotateOnWorldAxis(y, props.rotation[1]);
	mesh.rotateOnWorldAxis(x, -Math.PI / 2);
	// mesh.rotateOnWorldAxis(z, props.rotation[2]);

	// APPLIED TRANSFORMATION MATRIX IN CASE MATRIX IS USED FOR ROTATION AND TRANSLATION
	// const transformationMatrix = new THREE.Matrix4();
	// transformationMatrix.set(
	// 	-0.0652291,
	// 	-0.77580473,
	// 	0.62759237,
	// 	0,
	// 	0.67344542,
	// 	-0.49832986,
	// 	-0.5460207,
	// 	0,
	// 	-0.73635346,
	// 	-0.38703277,
	// 	-0.55496776,
	// 	0,
	// 	0.0,
	// 	0.0,
	// 	0.0,
	// 	1
	// );
	// mesh.applyMatrix4(transformationMatrix);
	// const newbbox1 = CalculateBoundingBox(newbbox);
	// setbb(newbbox1);
	console.log("bbb--", bb);
	if (bb) mesh.position.set(...alignToPlate(props.position, bb));

	return <primitive object={mesh} ref={meshref} />;
};

function Model() {
	const result = useLoader(STLLoader, "/Error_Cylinder.stl");
	// You don't need to check for the presence of the result, when we're here
	// the result is guaranteed to be present since useLoader suspends the component
	const material = new THREE.MeshStandardMaterial({
		color: "#818181",
		wireframe: false,
	});

	material.side = THREE.DoubleSide;

	const mesh = new THREE.Mesh(
		result,
		material
		// new THREE.MeshStandardMaterial({ color: "#818181", wireframe: false }),
	);
	mesh.castShadow = true;

	return <primitive object={mesh} />;
}

//FLOOR GEOMETRY
const Floor = (props) => {
	const texture = useLoader(TextureLoader, "/baseplate.jpg");
	return (
		<mesh {...props} style={{ background: "grey" }} receiveShadow>
			{/* <boxBufferGeometry args={[150, 14, 150]} /> */}
			<boxBufferGeometry args={[props.length, 14, props.width]} />

			<meshPhysicalMaterial map={texture} />
			{/* <meshPhysicalMaterial color={"#262626"} /> */}
			{/* <meshBasicMaterial /> */}
		</mesh>
	);
};

//LIGHT GEOMETRY
const Bulb = (props) => {
	return (
		<mesh {...props}>
			<pointLight castShadow />
			{/* takes radius in args */}
			{/* <sphereBufferGeometry args={[10]} /> */}
			<meshPhongMaterial emissive="white" />
		</mesh>
	);
};

// FUNCTIONS
const CalculateBoundingBox = (stlfile) => {
	const val = stlfile.boundingBox;
	return val;
};
const alignToPlate = (position, boundingBox) => {
	if (!position || position.length !== 3 || !boundingBox) return;
	const newPosition = [...position];
	const geometryHeight = boundingBox.max.y - boundingBox.min.y;
	newPosition[1] = geometryHeight / 2; // Adjust the position along the y-axis
	return newPosition;
};

// RENDERER MAIN FUNCTION TO ENTER INTO THE STL RENDERER -------------------------------------------------
const Renderer = (props) => {
	const [pos, setPos] = useState([0, 0, 0]);
	const [loadingError, setLoadingError] = useState(null);

	const scale = 0.001;
	//   const { boundngbox, rotation } = useStl();
	console.log("mmmmdls--", props.models);
	// const models = props.models?.map((model, index) => (
	// 	<Geom
	// 		key={index}
	// 		name={model.link}
	// 		position={[
	// 			model.coordinates[0] - props.length / 2,
	// 			0,
	// 			model.coordinates[1] - props.width / 2,
	// 		]}
	// 		rotation={model.orientation}
	// 	/>
	// 	// <Geom
	// 	//     name="Error_Robohead.stl"
	// 	//     position={[-25, 20, 23]}
	// 	//     rotation={[1.5708, 0, 0]}
	// 	// />
	// ));
	// const models = props.models?.map((model, index) => {
	// 	if (model.link) {
	// 		return (
	// 			<Suspense fallback={<Model />}>
	// 				<Geom
	// 					name={model}
	// 					position={[
	// 						model.coordinates[0] - props.length / 2,
	// 						0,
	// 						model.coordinates[1] - props.width / 2,
	// 					]}
	// 					rotation={model.orientation}
	// 				/>
	// 			</Suspense>
	// 		);
	// 	}
	// });

	const models = props.models?.map((model, index) => {
		// if (!navigator.onLine) {
		// 	// Check if the user is offline and display an error message
		// 	return <Model />;
		// }
		try {
			if (model.link) {
				return (
					// <Suspense
					// 	key={index}
					// 	fallback={<Model />}
					// >
					<Geom
						name={model.link}
						position={[
							model.coordinates[0] - props.length / 2,
							0,
							model.coordinates[1] - props.width / 2,
						]}
						rotation={model.orientation}
					/>
					// </Suspense>
				);
			}
		} catch (error) {
			console.error(`Error rendering model ${model.link}:`, error);
			return (
				<div key={index}>
					<p>Error rendering model {model.link}</p>
				</div>
			);
		}
	});

	useEffect(() => {
		console.log("MY link-", props.link);
	}, []);

	return (
		<div className="modal">
			<div className="modal-content">
				<div className="canvas-main-container">
					<div className="canvas-header">
						<div
						// style={{
						//   zIndex: "1000",
						//   color: "white",
						//   position: "absolute",
						//   left: "0",
						//   padding: "10px",
						//   backgroundColor: "red",
						// }}
						>
							{`Plate size ${props.length} x ${props.width}`}
						</div>
						<div
							// style={{
							//   zIndex: "1000",
							//   position: "absolute",
							//   right: "0",
							//   padding: "10px",
							// }}
							onClick={props.onClose}
						>
							<MdClose size={25} color="white" />
						</div>
					</div>

					{/* <div className="renderer-sidebar">
            <div className="tool-pointer-select">
              <FaMousePointer />
            </div>
            <div className="tool-pointer-select">
              <MdBuild />
            </div>
            <div className="tool-pointer-select">
              <MdRefresh />
            </div>
            <div className="tool-pointer-select">
              <MdLayers />
            </div>
          </div> */}
					<Canvas
						shadows
						style={{
							background: " linear-gradient(to right, #232526, #414345)",
						}}
						camera={{
							position: [100, 100, 100],
						}}
						pixelRatio={window.devicePixelRatio}
					>
						<Suspense fallback={<Loader />}>
							<Axes length={props.length} />
							{models}
						</Suspense>

						<Suspense fallback={<Loader />}>
							<Floor
								length={props.length}
								width={props.width}
								position={[0, -7, 0]}
							/>
						</Suspense>

						<Bulb position={[200, 200, 200]} />
						<Bulb position={[-200, 200, -200]} />
						<Bulb position={[200, 200, -200]} />
						<Bulb position={[-200, 200, 200]} />

						<ambientLight intensity={0} />

						<CameraController />
					</Canvas>

					<div className="canvas-bottom">
						<p>
							<span style={{ fontWeight: "100", opacity: "0.4" }}> </span>
						</p>
						<p
							style={{
								fontWeight: "80",
								opacity: "0.4",
								fontSize: "12px",
								float: "right",
							}}
						>
							Powered by Intech Additive Solutions Pvt Ltd
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Renderer;
