import { useEffect, useState } from "react";
import "./step1.css";
import Step1Card from "./Step1Card";
import { FolderDropdown } from "../../Components";

// Library Imports
import { BiAddToQueue } from "react-icons/bi";
import { MdError } from "react-icons/md";
import { FaToolbox } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";

//Redux Imports
import { useDispatch, useSelector } from "react-redux";
import { addCard, setErrors, setJobName } from "../../Store/Slice/stepperSlice";

const Step1 = () => {
  const [maxMaterialError, setMaxMaterialError] = useState(false);

  const dispatch = useDispatch();
  const jobNameError = useSelector((state) => state.stepperSlice.jobNameError);
  const jobName = useSelector((state) => state.stepperSlice?.job);
  const cards = useSelector((state) => state.stepperSlice.job?.materials);
  const jobId = useSelector((state) => state.stepperSlice.job.id);
  const materialLength = useSelector(
    (state) => state.stepperSlice.job.materials,
  );

  const handleAddNewCard = () => {
    if (materialLength.length >= 3) {
      setMaxMaterialError(true);
      return;
    }

    const id = uuidv4();

    dispatch(addCard({ id: id }));
  };

  const handleInputChange = (event) => {
    const value = event.target.value;

    if (value.trim() === "") {
      dispatch(
        setErrors({
          cardNumber: -1, // Assuming the job name error is not related to a specific card
          name: "jobName",
          errorMessage: "Job Name can't be blank",
        }),
      );
    } else {
      dispatch(
        setErrors({
          cardNumber: -1,
          name: "jobName",
          errorMessage: "", // Clear the error message if the job name is not empty
        }),
      );
    }

    dispatch(setJobName(value));
  };

  useEffect(() => {
    if (materialLength.length >= 3) {
      setMaxMaterialError(true);
    } else {
      setMaxMaterialError(false);
    }

    // dispatch(
    //   setErrors({
    //     cardNumber: -1,
    //     name: "jobName",
    //     errorMessage: "", // Clear the error message if the job name is not empty
    //   })
    // );
  }, [materialLength]);

  // const handleCardPartsToggle = (cardId) => {
  // 	setCardPartsOpen((prevOpen) => {
  // 		const updatedOpen = [...prevOpen];
  // 		const index = updatedOpen.indexOf(cardId);
  // 		if (index !== -1) {
  // 			updatedOpen.splice(index, 1);
  // 		} else {
  // 			updatedOpen.push(cardId);
  // 		}
  // 		return updatedOpen;
  // 	});
  // };

  return (
    <div className="step1-container">
      <div className="step1-left-container">
        <FolderDropdown />
      </div>

      <div className="step1-right-container">
        <div className="step1-card-container">
          <div>
            <p>Enter Job Name</p>
          </div>

          <div className="job-container">
            <div className="field email">
              <div className="input-area">
                <input
                  type="text"
                  maxLength={50}
                  value={jobName.job_name}
                  placeholder="Enter Job Name"
                  onChange={handleInputChange}
                />
                {!jobNameError && <FaToolbox size={20} />}

                {jobNameError && <MdError className="error-icon" size={25} />}
              </div>

              {jobNameError && (
                <div className="error-message">{jobNameError}</div>
              )}
            </div>
          </div>

          {cards &&
            cards.map((card, index) => {
              return (
                <Step1Card
                  key={card.id}
                  id={card.id}
                  cardNumber={index}
                  jobId={jobId}
                  materials={card.materials}
                />
              );
            })}

          <button
            className="addButton"
            onClick={handleAddNewCard}
            disabled={maxMaterialError}
          >
            <div>
              <BiAddToQueue size={23} />

              <p>Add New Material </p>
            </div>
          </button>

          {maxMaterialError && (
            <div className="error-message">
              Maximum of three materials allowed.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Step1;
