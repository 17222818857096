import { Html, useProgress } from "@react-three/drei";

//LOADER SPINNER
function Loader() {
	const { progress } = useProgress();
	const loaderStyle = {
		color: "white",
		width: "300px",
		gap: "1rem",
		backgroundColor: "black",
		padding: "20px",
		display: "flex",
		opacity: "0.8",
		justifyContent: "center",
		alignItems: "center",
	};
	const progressStyle = {
		// opacity: 0.8,
	};
	const spinnerStyle = {
		borderTopColor: "white",
		borderLeftColor: "white",
		borderBottomColor: "transparent",
		borderRightColor: "transparent",
		animation: "spin 1s infinite linear",
		width: "20px",
		height: "20px",
		borderWidth: "2px",
		borderStyle: "solid",
		borderRadius: "50%",
	};
	return (
		<Html center style={loaderStyle}>
			<div style={spinnerStyle} />
			<div style={progressStyle}>{progress.toFixed(2)} % loaded</div>
			<style>
				{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
			</style>
		</Html>
	);
}
export default Loader;
