import { HorizontalBarLoader } from "Components";
import { MdOutline3DRotation } from "react-icons/md";
import { trimPartName } from "Utils/utils";

export const PartsList = ({
  builds,
  selectedBuild,
  uniqueParts,
  handlePartModalToggle,
  buildsLoading,
}) => {
  return (
    <>
      <div>
        <div className="part-list-header">
          <h2>Parts</h2>
        </div>

        <div className="part-list-container">
          <div className="part-header">
            <div
              className="part-cell"
              style={{
                backgroundColor: "lightgrey",
                borderBottom: "2px solid black",
              }}
            >
              <h2>Part Name</h2>
            </div>

            <div
              className="part-cell"
              style={{
                backgroundColor: "lightgrey",
                borderBottom: "2px solid black",
              }}
            >
              <h2> Quantity</h2>
            </div>
          </div>

          {!builds?.length ? (
            buildsLoading.hasError === true &&
            buildsLoading.loading === false ? (
              <div
                style={{
                  height: "40vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "red",
                }}
              >
                <p>Error occurred. Please try again.</p>
              </div>
            ) : (
              <div
                style={{
                  height: "40vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <HorizontalBarLoader />
              </div>
            )
          ) : (
            // PARTS LIST - SELECTED BUILD PARTS LIST

            <div className="builds-list part-list" style={{ height: "44vh" }}>
              {selectedBuild !== null &&
                builds &&
                builds[selectedBuild] &&
                uniqueParts.map((part, index) => (
                  <div className="part-row" key={index}>
                    {/* <div className="part-cell">{trimPartName(part.name)}</div> */}

                    <div className="part-cell">
                      {trimPartName(part.name, 25)}
                    </div>
                    <div
                      className="part-cell"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Quantity: {part.quantity}
                      <span>
                        <button
                          className="view-btn"
                          onClick={() => handlePartModalToggle(part)}
                        >
                          <MdOutline3DRotation /> View
                        </button>
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>

      <div className="build-time-container">
        <div>Build time </div>

        <span>
          {selectedBuild !== null && builds && builds[selectedBuild] ? (
            <>{builds[selectedBuild].build_time} hours</>
          ) : (
            <HorizontalBarLoader />
          )}
        </span>
      </div>
    </>
  );
};
