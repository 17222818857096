import React, { useEffect, useState } from "react";
import "./step3.css";
import Renderer from "../../ModelRenderer/Renderer";
import { getObjectUrlFromS3 } from "../../AWS/awsService";
import { HorizontalBarLoader } from "../../Components/BarLoader/BarLoader.jsx";
import { FolderDropdown } from "../../Components/FolderDropdown/FolderDropdown";
import { trimPartName } from "../../Utils/utils";

import PartRenderer from "../../ModelRenderer/PartRenderer";
import { MdClose, MdFullscreen } from "react-icons/md";

//New Imports
import ThreeJSRenderer from "../../ModelRenderer/PartRender.js";
import ThreeJSModelRenderer from "ModelRenderer/ModelRender.js";

//Library Imports
import { MdInfoOutline, MdOutline3DRotation } from "react-icons/md";
import { Tooltip as ReactTooltip } from "react-tooltip";

//Redux Imports
import { useDispatch, useSelector } from "react-redux";
import {
	buildRes,
	setBackDisable,
	setFolderOpen,
	setUniqueParts,
	setUploadingDisable,
} from "../../Store/Slice/stepperSlice";
import { DirectConnect } from "aws-sdk";
import { BuildsList } from "./BuildsList/BuildsList";
import ErrorBoundaryRenderer from "./ErrorBoundary/ErrorBoundaryRenderer";
import ErrorBoundaryPartRenderer from "./ErrorBoundary/ErrorBoundaryPartRenderer";
import { PartsList } from "./PartsList/PartsList";
import ModelRender from "ModelRenderer/ModelRender.js";
import PartRender from "../../ModelRenderer/PartRender.js";
import BuildRender from "ModelRenderer/ModelRender.js";

const Step3 = () => {
	//New
	const [isFullScreen, setIsFullScreen] = useState(false);

	const [showBuildModal, setShowBuildModal] = useState(false);
	const [showPartModal, setShowPartModal] = useState(false);
	const [showOldModal, setShowOldModal] = useState(false);
	const [selectedBuild, setSelectedBuild] = useState(0);
	const [buildModels, setBuildModels] = useState([]);
	const [length, setLength] = useState(null);
	const [width, setWidth] = useState(null);
	const [selectedPart, setSelectedPart] = useState(null); //Contains part name and link for rendering

	const dispatch = useDispatch();
	const uniqueParts = useSelector((state) => state.stepperSlice.uniqueParts);
	const isDrawerOpen = useSelector(
		(state) => state.stepperSlice.drawer.isDrawerOpen
	);
	const folderOpen = useSelector((state) => state.stepperSlice.folderOpen);
	const userID = useSelector((state) => state.authSlice.user?.id);
	const jobId = useSelector((state) => state.stepperSlice.job?.id);
	const builds = useSelector((state) => state.stepperSlice.job?.builds);
	const buildsLoading = useSelector(
		(state) => state.stepperSlice?.buildsLoadingDisable
	);

	// const models = [
	//   {
	//     link: "Error_Cylinder.stl",
	//     coordinates: [-35, 24 / 4, -64],
	//     orientation: [0, 0, Math.PI / 2],
	//   },
	//   // Add more models here as needed
	// ];

	useEffect(() => {
		console.log("Running build Res");

		//Fetches the builds
		dispatch(buildRes());
	}, [dispatch, isDrawerOpen]);

	const getseUrlofPart = async (path) => {
		return await getObjectUrlFromS3(path);
	};

	//Sets the partslist in the redux with the signed urls, sets unique partslist for selectedBuild
	useEffect(() => {
		const fetchBuildData = async () => {
			const parts = builds[selectedBuild]?.parts_list || [];
			const length = builds[selectedBuild]?.base_plate_length;
			const width = builds[selectedBuild]?.base_plate_breadth;
			console.log(builds[selectedBuild]);

			setLength(length);
			setWidth(width);

			//TODO:Create array of object using reducer
			//WARN: Receiving same id for every part from backend
			//Took 2 steps conversion instead of making array directly due to time complexity, which is less than search in the array each time
			const uniquePartsMap = parts.reduce((map, part) => {
				if (map[part.name]) {
					map[part.name].count += 1;
					map[part.name].material = part.material;
					map[part.name].id = part.id;
				} else {
					map[part.name] = {
						count: 1,
						material: part.material,
						id: part.id,
					};
				}

				return map;
			}, {});

			//Used to display parts list for each selectedBuild
			// const uniquePartsArray = Object.keys(uniquePartsMap).map((name) => ({
			// 	name,
			// 	id: uniquePartsMap[name].id,
			// 	count: uniquePartsMap[name].count,
			// 	quantity: uniquePartsMap[name].count,
			// 	material: uniquePartsMap[name].material,
			// 	link: (async (name) => {
			// 		const path =
			// 			`${userID}/job-${jobId}/material-${builds[selectedBuild]?.material_id}/` +
			// 			name;
			// 		return await getseUrlofPart(path);
			// 	})(),
			// }));

			const uniquePartsArray = await Promise.all(
				Object.keys(uniquePartsMap).map(async (name) => {
					const path =
						`${userID}/job-${jobId}/material-${builds[selectedBuild]?.material_id}/` +
						name;
					const link = await getseUrlofPart(path);

					return {
						name,
						id: uniquePartsMap[name].id,
						count: uniquePartsMap[name].count,
						quantity: uniquePartsMap[name].count,
						material: uniquePartsMap[name].material,
						link,
					};
				})
			);

			console.log(uniquePartsArray);
			dispatch(setUniqueParts(uniquePartsArray));

			console.log(parts);
			const models = parts.map((link) => ({
				id: link.id,
				name: link.name,
				link: uniquePartsArray.find((part) => part.name === link.name).link,
				coordinates: link.coordinates,
				orientation: link.orientation,
			}));
			console.log(models);

			setBuildModels(models);
		};

		if (selectedBuild !== null && builds) {
			fetchBuildData();
		}

		// For Disabling next and back button while fetching the builds
		builds?.length
			? dispatch(setUploadingDisable(false))
			: dispatch(setUploadingDisable(true));

		builds?.length
			? dispatch(setBackDisable(false))
			: dispatch(setBackDisable(true));
	}, [selectedBuild, builds, jobId]);

	function handleModalToggle(index) {
		if (showBuildModal) {
			// setSelectedBuild(null);
			setShowBuildModal(!showBuildModal);
		} else {
			setSelectedBuild(index);
			setShowBuildModal(!showBuildModal);
		}
	}

	async function handlePartModalToggle(part) {
		setSelectedPart(part);
		setShowPartModal(!showPartModal);
	}

	async function handleOldModalToggle(part) {
		setSelectedPart(part);
		setShowOldModal(!showOldModal);
	}

	function handleBuildClick(index) {
		setSelectedBuild(index);
	}

	const handleFolderToggle = () => {
		dispatch(setFolderOpen(!folderOpen));
	};

	//Full Screen
	const handleBuildFullScreen = () => {
		setIsFullScreen(!isFullScreen);
		const renderer = document.querySelector(".threejs-main-container canvas");
		if (renderer) {
			if (!isFullScreen) {
				renderer.requestFullscreen();
			} else {
				document.exitFullscreen();
			}
		}
		return null;
	};

	//Full Screen
	const handleModelFullScreen = () => {
		setIsFullScreen(!isFullScreen);
		const renderer = document.querySelector(
			"#modal-renderer.threejs-model-container canvas"
		);
		if (renderer) {
			if (!isFullScreen) {
				renderer.requestFullscreen();
			} else {
				document.exitFullscreen();
			}
		}
		return null;
	};

	console.log(buildModels);
	return (
		<div className="step3-container">
			<div className="step1-left-container">
				<FolderDropdown />
			</div>

			<div className="step3-right-container">
				<div className="build-list-container">
					<div className="build-list-header">
						<h2
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							Builds
							<MdInfoOutline
								color="black"
								data-tooltip-id="builds-target"
								data-tooltip-content="Builds are parts which gets assigned to a particular machine. 	
								Multiple builds could be available from same machine. "
								data-tooltip-delay-show="150"
							/>
							<ReactTooltip
								id="builds-target"
								place="right"
								style={{
									width: "25rem",
									fontWeight: "500",
									fontSize: "medium",
									zIndex: "2",
								}}
							/>
						</h2>
					</div>

					<div className="builds-list" style={{ height: "auto" }}>
						<BuildsList
							handleBuildClick={handleBuildClick}
							selectedBuild={selectedBuild}
							builds={builds}
							handleModalToggle={handleModalToggle}
							handleOldModalToggle={handleOldModalToggle}
						/>
					</div>

					{/* New Renderer */}
					{showBuildModal && (
						<ErrorBoundaryRenderer onClose={() => setShowBuildModal(false)}>
							<div className="modal">
								<BuildRender
									buildModels={buildModels}
									length={length}
									width={width}
									selectedPart={selectedPart}
									handleFullScreen={handleModelFullScreen}
									handleModalToggle={handleModalToggle}
								/>
							</div>
						</ErrorBoundaryRenderer>
					)}

					{showPartModal && (
						<ErrorBoundaryPartRenderer onClose={() => setShowPartModal(false)}>
							<div className={"modal"}>
								<PartRender
									selectedPart={selectedPart}
									handleFullScreen={handleBuildFullScreen}
									handlePartModalToggle={handlePartModalToggle}
									uniqueParts={uniqueParts}
								/>
							</div>
						</ErrorBoundaryPartRenderer>
					)}

					{/* Himanshu's Renderer */}
					{showOldModal && (
						<ErrorBoundaryPartRenderer onClose={() => setShowOldModal(false)}>
							<div className={"modal"}>
								<Renderer
									models={buildModels}
									length={length}
									width={width}
									onClose={() => setShowOldModal(false)}
								/>
							</div>
						</ErrorBoundaryPartRenderer>
					)}
				</div>

				<div
					className="part-list-container"
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
					}}
				>
					<PartsList
						selectedBuild={selectedBuild}
						builds={builds}
						uniqueParts={uniqueParts}
						handlePartModalToggle={handlePartModalToggle}
						buildsLoading={buildsLoading}
					/>
				</div>
			</div>
		</div>
	);
};

export default Step3;
