import { MdClose } from "react-icons/md";

export const Popup = ({
	onClose,
	handleSave,
	handleCancel,
	isSaving,
	isDeleting,
	triggeredBy,
	jobName,
}) => {
	return (
		<div className="popup-container">
			<div className="popup-backdrop" />
			<div className="popup-content">
				<div className="popup-header">
					<h2 className="popup-title">
						{triggeredBy === "settings"
							? "Delete Machine"
							: triggeredBy === "jobs"
							? "Delete Job"
							: "Save Job"}
					</h2>
					<button
						className="popup-close-button"
						onClick={onClose}
					>
						<MdClose />
					</button>
				</div>
				{isDeleting ? (
					<p>Deleting...</p>
				) : (
					<p>
						{triggeredBy === "settings"
							? "Do you want to delete this machine?"
							: triggeredBy === "jobs"
							? `Do you want to delete the Job?`
							: "Do you want to save the changes?"}
					</p>
				)}
				<div className="popup-buttons">
					<button
						className="popup-button primary"
						onClick={handleSave}
						disabled={isSaving || isDeleting}
					>
						{triggeredBy ? "Delete" : isSaving ? "Saving..." : "Save"}
					</button>
					<button
						className="popup-button secondary"
						onClick={handleCancel}
						disabled={isSaving || isDeleting}
					>
						{triggeredBy ? "Cancel" : "Don't Save"}
					</button>
				</div>
			</div>
		</div>
	);
};
