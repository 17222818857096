import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../api/axios";

const homeSlice = createSlice({
  name: "home",

  initialState: {
    homeData: null,
    status: null,
  },

  reducers: {
    setHomeData(state, action) {
      state.homeData = action.payload.data;
    },

    clearHomeData(state) {
      state.homeData = null;
    },

    addInHomeData(state, action) {
      console.log("received - ", action.payload);
      const res = action.payload;

      const existingDataIndex = state.homeData.findIndex(
        (item) => item.id === res.id,
      );

      if (existingDataIndex >= 0) {
        // Update the existing data
        // existingData.name = newData.name;
        state.homeData = state.homeData.map((obj, index) =>
          existingDataIndex === index ? res : obj,
        );
      } else {
        // Push the new data into the array
        state.homeData.push(res);
      }

      // state.homeData.push(action.payload);
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchJobs.pending, (state, action) => {
        state.status = "loading";
      })

      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.homeData = action.payload;
        state.status = "success";
      })

      .addCase(deleteJob.pending, (state) => {})

      .addCase(deleteJob.fulfilled, (state, action) => {
        state.homeData = state.homeData.filter(
          (job) => job.id !== action.payload,
        );
      });
  },
});

export const { setHomeData, clearHomeData, addInHomeData } = homeSlice.actions;

export default homeSlice.reducer;

//Gets the latest jobs
export const fetchJobs = createAsyncThunk("get/jobs", async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const userId = state.authSlice.user.id;

  try {
    const jobs = await axios.get(`/api/v1/job/user/${userId}/jobs`); // api call using axios to get
    return jobs.data;
  } catch (error) {
    console.log(error);
  }
});

export const deleteJob = createAsyncThunk(
  "delete/homeData",
  async (jobId, thunkAPI) => {
    const state = thunkAPI.getState();
    const userId = state.authSlice.user.id;

    try {
      const response = await axios.delete(
        `/api/v1/job/user/${userId}/job/${jobId}`,
      );

      if (response.status === 200) {
        return jobId;
      }
    } catch (error) {
      console.log(error);
    }
  },
);
