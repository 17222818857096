import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "./Pages/Home";
import Parts from "./Parts";
import PartEstimation from "./Pages/PartEstimation";

// import Settings from "./Pages/Settings";
import SettingsPage from "Pages/Settings/SettingsPage";

import { useEffect } from "react";
import PrivateRoute from "./privateRoute";

function App() {
	const navigate = useNavigate();

	useEffect(() => {
		document.title = "AM Costing";
		navigate("dashboard/home");
	}, []);

	return (
		<div>
			<Routes>
				<Route path="/dashboard" element={<PrivateRoute />}>
					<Route path="home" element={<Home />} />
					<Route path="myparts" element={<Parts />} />
					<Route path="pastestimation" element={<PartEstimation />} />
					<Route path="settings" element={<SettingsPage />} />
					{/* <Route path="settings" element={<Settings />} /> */}
				</Route>
			</Routes>
		</div>
	);
}

export default App;
