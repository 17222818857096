import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import axios from "./api/axios";
import { Sidebar } from "./Components";

import { clearUser, setUser } from "./Store/Slice/authSlice";
const PrivateRoute = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { loading, isAuthenticated } = useSelector((state) => state.authSlice);

	useEffect(() => {
		document.title = "AM Costing";
		const fetchUser = async () => {
			try {
				const userData = await axios.get(`/api/v1/auth`); // api call using axios to get
				console.log(`Is verified user - ${userData.data.response}`);
				dispatch(setUser({ user: userData.data.response }));
				console.log("isAuthenticated ->", isAuthenticated);
				navigate("/dashboard/home");
			} catch (error) {
				console.log("xhr", error);
				if (error && error.status === 401) {
					console.log("Unauthorized access. Please log in.", error);
					dispatch(clearUser());
				} else {
					// Handle other errors
					console.log("Error--:", error);
					//   dispatch(clearUser());
				}
			}
		};

		fetchUser();
	}, []);

	if (!isAuthenticated) {
		setTimeout(() => {
			window.location.href = process.env.REACT_APP_AUTH_URL;
		}, 4000);
	}
	// const { token } = useAuth();
	return isAuthenticated ? (
		<>
			<Sidebar />

			{/* <Outlet /> */}
		</>
	) : (
		<>
			<h1>Unauthorized user detected. Redirecting to Login... </h1>
		</>
	);
};
export default PrivateRoute;
