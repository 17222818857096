import { Outlet } from "react-router-dom";

const Parts = () => {
  return (
    <div>
      <h1>Parts to Display</h1>

      <Outlet />
    </div>
  );
};
export default Parts;
