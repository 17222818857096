import "./buildsList.css";

//Library Imports
import { MdOutline3DRotation, MdOutlineError } from "react-icons/md";

//Redux Imports
import { useDispatch, useSelector } from "react-redux";
import { HorizontalBarLoader } from "Components";
import { buildRes } from "Store/Slice";

export const BuildsList = (props) => {
	const buildsLoading = useSelector(
		(state) => state.stepperSlice?.buildsLoadingDisable
	);

	const dispatch = useDispatch();

	if (buildsLoading?.loading === false && buildsLoading?.hasError === false) {
		console.log("IN If");

		return props.builds?.map((build, index) => (
			<div
				className={`builds ${props.selectedBuild === index ? "active" : ""}`}
				key={index}
			>
				<div>
					<button onClick={() => props.handleBuildClick(index)}>
						<span>{`Build ${index}`}</span>

						<br />

						<span style={{ fontSize: "x-small" }}>
							{build.parts_list[0].material}
						</span>
					</button>
				</div>

				<div>
					<button
						className="arrow"
						onClick={() => props.handleModalToggle(index)}
					>
						<MdOutline3DRotation size={30} />
					</button>

					<button
						className="arrow"
						onClick={() => props.handleOldModalToggle(index)}
					>
						<MdOutline3DRotation size={30} />
					</button>
				</div>
			</div>
		));
	} else if (
		buildsLoading.hasError === true &&
		buildsLoading.loading === false
	) {
		console.log("IN ELSE IF");

		return (
			<div className="try-again-button-container">
				<div>
					<button
						className="try-again-button"
						onClick={() => dispatch(buildRes())}
					>
						<MdOutlineError size={17} /> Try Again
					</button>
				</div>

				<div className="placeholder">
					Oops! An error occurred while fetching builds. Please try again.
				</div>
			</div>
		);
	} else {
		console.log("IN ELSE");

		return (
			<div
				style={{
					height: "50vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<HorizontalBarLoader />
			</div>
		);
	}
};
