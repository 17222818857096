import "./BarLoader.css";

export const HorizontalBarLoader = () => {
	return (
		<div className="horizontal-wave-loader-container">
			<div className="horizontal-wave-loader"></div>
			<div className="horizontal-wave-loader"></div>
			<div className="horizontal-wave-loader"></div>
		</div>
	);
};
