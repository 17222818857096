import { clearUser } from "./Slice/authSlice";
// import { logout } from "../services/authService";
import { setUser } from "./Slice/authSlice";

const AuthMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.type === "/") {
      dispatch(setUser(action.payload.user));
      // console.log("authmiddleware setting user");
    } else if (action.type === "api/jwtAuth/rejected") {
      dispatch(clearUser());
      // ("logging out user auth middleware");
      // logout();
      window.location.href = "/login"; // Redirect to login page
    }
    return next(action);
  };

export default AuthMiddleware;
