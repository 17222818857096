import { useState } from "react";
import "./completedjobs.css";
import { Loader } from "../Loader/Loader";
import { Popup } from "../Popup/Popup";

//Library Imports
import {
  MdPlayArrow,
  MdNavigateBefore,
  MdNavigateNext,
  MdDelete,
} from "react-icons/md";

//Redux imports
import { useDispatch, useSelector } from "react-redux";
import { showCompletedJob } from "../../Store/Slice/stepperSlice";
import { deleteJob } from "../../Store/Slice/homeSlice";
import { LoadingDots } from "../LoadingDots/LoadingDots";

export const CompletedJobs = () => {
  const [selectedValue, setSelectedValue] = useState("option1");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState({
    id: null,
    name: null,
  });

  const dispatch = useDispatch();
  const completeJobList = useSelector(
    (state) => state.homeSlice.homeData,
  )?.filter((job) => job.status === true);
  const status = useSelector((state) => state.homeSlice.status);
  const searchQuery = useSelector(
    (state) => state.stepperSlice.search?.completedJobSearchQuery,
  );
  const { completedJobStatus } = useSelector((state) => state.stepperSlice);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  function handleChange(event) {
    setSelectedValue(event.target.value);
  }

  // Pagination state
  const itemsPerPage = 4; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate pagination boundaries
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentJobs = completeJobList?.slice(indexOfFirstItem, indexOfLastItem);
  const isDrawerOpen = useSelector(
    (state) => state.stepperSlice.drawer.isDrawerOpen,
  );

  const handleDeleteJob = (jobID) => {
    dispatch(deleteJob(jobID));
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(completeJobList?.length / itemsPerPage);

  const handleViewClick = (jobId) => {
    dispatch(showCompletedJob(jobId));
  };

  const ViewJobStatusComponent = ({ jobId }) => {
    if (jobId === completedJobStatus.id) {
      switch (completedJobStatus.status) {
        case "pending":
          return (
            <div style={{ padding: "6px 14px" }}>
              <LoadingDots />
            </div>
          );

        case "fulfilled":
          return (
            <>
              <MdPlayArrow className="drop-link" /> View
            </>
          );

        case "rejected":
          return <div style={{ color: "red", padding: "6px 14px" }}>Error</div>;

        default:
          return (
            <>
              <MdPlayArrow className="drop-link" /> View
            </>
          );
      }
    } else {
      return (
        <>
          <MdPlayArrow className="drop-link" /> View
        </>
      );
    }
  };

  return (
    <div
      className="Completed-jobs-list-container"
      // style={{ height: "50vh", overflow: "hidden", overflowY: "scroll" }}
    >
      {/* {builds.map((build, index) => (
        <div key={index} className="jobs-list-item">
          {build}
          <div className="list-icon-container">
            <FaTrashAlt className="delete-list" />
            <div className="dropdown">
              <FaAngleDown className="drop-link" />
            </div>
          </div>
        </div>
      ))} */}
      {status === "loading" ? (
        <div
          style={{
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : completeJobList === undefined ? (
        <></>
      ) : completeJobList.length ? (
        <>
          {currentJobs
            ?.filter((job) =>
              job.name.toLowerCase().includes(searchQuery.toLowerCase()),
            )
            .map((job) => (
              <>
                <div key={job.id} className="jobs-list-item">
                  {job.name}
                  <div className="list-icon-container">
                    <div>Date: {job.updatedAt.split("T")[0]}</div>

                    <div>
                      <MdDelete
                        size={30}
                        className="delete-list"
                        onClick={() => {
                          setSelectedJob({ id: job.id, name: job.name });
                          togglePopup();
                        }}
                      />
                    </div>

                    <div className="dropdown">
                      <button
                        onClick={() => {
                          handleViewClick(job.id);
                        }}
                        className="normal-black-button"
                      >
                        <ViewJobStatusComponent jobId={job.id} />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ))}
          {isPopupOpen && (
            <Popup
              onClose={togglePopup}
              handleSave={() => {
                handleDeleteJob(selectedJob.id);
                togglePopup();
              }}
              handleCancel={togglePopup}
              triggeredBy={"jobs"}
              jobName={selectedJob.name}
            />
          )}
          {/* Pagination */}
          <div className="pagination">
            <button
              className="pagination-button"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <MdNavigateBefore />
            </button>
            {Array.from({ length: totalPages }, (_, index) => index + 1).map(
              (pageNumber) => (
                <button
                  key={pageNumber}
                  className={`pagination-button ${
                    pageNumber === currentPage ? "active" : ""
                  }`}
                  onClick={() => paginate(pageNumber)}
                >
                  {pageNumber}
                </button>
              ),
            )}
            <button
              className="pagination-button"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <MdNavigateNext />
            </button>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "lightgrey",
            fontSize: "2.5vw",
            margin: "1rem",
            zIndex: "-1",
          }}
        >
          <p>No completed jobs to display, get started by adding a new job!</p>
          <ol
            style={{
              fontSize: "1.8vw",
              margin: "3rem 3rem 3rem 5rem",
            }}
          >
            <li>Create job: Click the 'Create Job' button to begin.</li>
            <li>Add quantity: Specify the quantity or details of the job.</li>
            <li>Review: Double-check the job information for accuracy.</li>
            <li>
              Invoice: Complete the job creation process by generating an
              invoice.
            </li>
          </ol>
        </div>
      )}
    </div>
  );
};
