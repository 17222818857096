import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { CurrencyConverter } from "../../Utils/utils";

// const CurrencyConverter = (currencyType) => {
//   switch (currencyType) {
//     case "INR":
//       return 1;
//
//     case "USD":
//       return 0.012;
//
//     case "EUR":
//       return 0.011;
//
//     case "YEN":
//       return 1.77;
//
//     default:
//       return 1;
//   }
// };

const initialState = {
  // machines: [
  // 	{
  // 		id: uuidv4(),
  // 		// sNo: 1,
  // 		machineName: "LF 1",
  // 		x: "150",
  // 		y: "150",
  // 		z: "10",
  // 		mhr: "2000",
  // 		numLasers: "16",
  // 		currencyType: "USD",
  // 	},
  // 	{
  // 		id: uuidv4(),
  // 		// sNo: 2,
  // 		machineName: "MF 1",
  // 		x: "350",
  // 		y: "350",
  // 		z: "10",
  // 		mhr: "1000",
  // 		numLasers: "6",
  // 		currencyType: "USD",
  // 	},
  // 	{
  // 		id: uuidv4(),
  // 		// sNo: 3,
  // 		machineName: "SF 1",
  // 		x: "450",
  // 		y: "450",
  // 		z: "10",
  // 		mhr: "4000",
  // 		numLasers: "12",
  // 		currencyType: "USD",
  // 	},
  // ],
  machines: [],
  showModal: false,
  isEditing: false,
  formData: {
    machineName: "",
    x: "",
    y: "",
    z: "",
    mhr: "",
    numLasers: "",
    currencyType: "",
  },
  formErrors: {},
  currencyType: "USD",
};

const settingsSlice = createSlice({
  name: "settings",

  initialState,

  reducers: {
    setForm: (state, action) => {
      state.isEditing = true;
      state.formData = state.machines.find(
        (machine) => machine.id === action.payload,
      );
    },

    openModal: (state) => {
      state.showModal = true;
    },

    closeModal: (state) => {
      state.showModal = false;
      state.isEditing = false;
      state.formErrors = {};
      state.formData = {
        machineName: "",
        x: "",
        y: "",
        z: "",
        mhr: "",
        numLasers: "",
        currencyType: "",
      };
    },

    resetForm: (state) => {
      state.formData = {
        machineName: "",
        x: "",
        y: "",
        z: "",
        mhr: "",
        numLasers: "",
        currencyType: "",
      };
      state.formErrors = {};
    },

    updateFormData: (state, action) => {
      const { name, value } = action.payload;
      state.formData[name] = value;
      state.formErrors[name] = ""; // Clear the error message when the field value changes
    },

    updateCurrency: (state, action) => {
      state.currencyType = action.payload;
    },

    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },

    deleteSingleMachine: (state, action) => {
      state.machines = state.machines.filter(
        (_, index) => index !== action.payload,
      );
    },

    currencyConversion: (state, action) => {
      const { settingsCurrencyType, authCurrencyType } = action.payload;
      console.log(authCurrencyType);

      state.machines = state.machines.map((machine, index) => {
        return {
          ...machine,
          mhr: CurrencyConverter(
            settingsCurrencyType,
            authCurrencyType,
            machine.mhr,
          ),
        };
      });

      state.currencyType = authCurrencyType;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(editMachine.fulfilled, (state, action) => {
        const machineIndex = state.machines.findIndex(
          (machine) => machine.id === action.payload.id,
        );
        state.machines[machineIndex] = action.payload;

        state.showModal = false;
        state.formData = {
          machineName: "",
          x: "",
          y: "",
          z: "",
          mhr: "",
          numLasers: "",
          currencyType: "",
        };
        state.formErrors = {};
      })

      .addCase(addMachine.fulfilled, (state, action) => {
        const { id } = action.payload;
        const newMachine = {
          id: id,
          sNo: state.machines.length + 1,
          ...state.formData,
        };

        state.machines.push(newMachine);
        state.showModal = false;
        state.formData = {
          machineName: "",
          x: "",
          y: "",
          z: "",
          mhr: "",
          numLasers: "",
          currencyType: "",
        };
        state.formErrors = {};
      })

      .addCase(deleteMachine.fulfilled, (state, action) => {
        state.machines = state.machines.filter(
          (Machine) => Machine.id !== action.payload,
        );
      })

      .addCase(getMachines.fulfilled, (state, action) => {
        state.machines = action.payload;
      });
  },
});

export const {
  setForm,
  openModal,
  closeModal,
  resetForm,
  updateCurrency,
  updateFormData,
  setFormErrors,
  deleteSingleMachine,
  currencyConversion,
} = settingsSlice.actions;

export default settingsSlice.reducer;

export const getMachines = createAsyncThunk(
  "get/machines",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("api/v1/machines", {
        headers: { "Content-Type": "application/json" },
      });

      console.log(response.data);
      return response.data.machines;
    } catch (err) {
      console.log(err);

      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(err.response.data);
    }
  },
);

export const editMachine = createAsyncThunk(
  "put/machine",
  async (updatedMachine, thunkAPI) => {
    try {
      const response = await axios.put(
        "/api/v1/machines",
        JSON.stringify({
          updatedMachine,
        }),
        {
          headers: { "Content-Type": "application/json" },
        },
      );
      if (response.status === 200) return updatedMachine;
      else console.log(response);
    } catch (err) {
      console.log(err);

      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(err.response.data);
    }
  },
);

export const addMachine = createAsyncThunk(
  "post/machine",
  async (newMachine, thunkAPI) => {
    try {
      const response = await axios.post(
        "/api/v1/machines",
        JSON.stringify({
          newMachine,
        }),
        {
          headers: { "Content-Type": "application/json" },
        },
      );

      if (response.status === 201) return newMachine;
      else console.log(response);
    } catch (err) {
      console.log(err);

      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteMachine = createAsyncThunk(
  "delete/machine",
  async (MachineID, thunkAPI) => {
    try {
      console.log("MachineID", MachineID);
      const response = await axios.delete(
        `/api/v1/machines?MachineID=${MachineID}`,
        {
          headers: { "Content-Type": "application/json" },
        },
      );

      if (response.status === 200) return MachineID;
      else console.log(response);
    } catch (err) {
      console.log(err);

      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(err.response.data);
    }
  },
);
