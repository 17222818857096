import React, { useState } from "react";
import "../Mhr/Mhr.css";
import { useSelector } from "react-redux";
import { MdClose } from "react-icons/md";

const Mhr = () => {
	const initialInputs = {
		machineCost: null,
		paybackPeriod: null,
		utilization: null,
		serviceCostPercentage: null,
		powerConsumption: null,
		buildingRent: null,
		consumables: null,
		manpowerCost: null,
		inertGasCost: null,
		avgFloodRate: null,
	};

	const fixedCostsArray = [
		{
			label: "machineCost",
			title: "Machine Cost",
			unit: "",
		},
		{
			label: "paybackPeriod",
			title: "Period of payback",
			unit: "yrs",
		},
		{
			label: "utilization",
			title: "Utilization of machine",
			unit: "%",
		},
	];

	const operatingCostsArray = [
		{
			label: "serviceCostPercentage",
			title: "Service cost as a % of list price",
			unit: "%",
		},
		{
			label: "powerConsumption",
			title: "Power consumption cost per hour",
			unit: "",
		},
		{
			label: "buildingRent",
			title: "Building rent per year",
			unit: "",
		},
		{
			label: "consumables",
			title: "Consumables cost per year(Recoater Blade, Filters, etc.)",
			unit: "",
		},
		{
			label: "manpowerCost",
			title: "Annual manpower cost on machine operation",
			unit: "",
		},
		{
			label: "inertGasCost",
			title: "Inert gas cost per liter",
			unit: "",
		},
		{
			label: "avgFloodRate",
			title: "Average flood rate while printing and flooding (liters per min)",
			unit: "L",
		},
	];

	const { currencyType } = useSelector((state) => state.authSlice);

	const [inputs, setInputs] = useState(initialInputs);
	console.log(inputs);
	console.log(fixedCostsArray);

	const [results, setResults] = useState({
		fixedCostPerHour: 0,
		operatingCosts: 0,
		totalCost: 0,
	});

	const [showDialog, setShowDialog] = useState(false);

	const handleCloseDialog = () => {
		setShowDialog(false);
		setInputs(initialInputs);
		window.scrollTo(0, 0);
	};

	const getCurrencySymbol = (code) => {
		const currencySymbols = {
			USD: "$",
			INR: "₹",
			EUR: "€",
			YEN: "¥",
		};
		return currencySymbols[code] || "";
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(inputs);

		const fixedCostPerYear = inputs.machineCost / inputs.paybackPeriod;
		const hoursOfOperation = 365 * 24 * (inputs.utilization / 100);
		const fixedCostPerHour = fixedCostPerYear / hoursOfOperation;
		console.log(fixedCostPerHour);

		const serviceCost =
			inputs.machineCost * (inputs.serviceCostPercentage / 100);
		const maintenanceCost = serviceCost / hoursOfOperation;
		const buildingRentPerHour = inputs.buildingRent / hoursOfOperation;
		const consumablesperHour = inputs.consumables / hoursOfOperation;
		const manpowerCostPerHour = inputs.manpowerCost / hoursOfOperation;
		const inertGasCostPerHour = inputs.inertGasCost * inputs.avgFloodRate * 60;
		console.log(serviceCost);

		const operatingCosts =
			maintenanceCost +
			inputs.powerConsumption +
			buildingRentPerHour +
			consumablesperHour +
			manpowerCostPerHour +
			inertGasCostPerHour;
		console.log(operatingCosts);

		const totalCost = fixedCostPerHour + operatingCosts;
		console.log(totalCost);

		setResults({
			fixedCostPerHour: parseFloat(fixedCostPerHour.toFixed(2)),
			operatingCosts: parseFloat(operatingCosts.toFixed(2)),
			totalCost: parseFloat(totalCost.toFixed(2)),
		});

		setShowDialog(true);
	};

	const InputComponent = ({ label, title, unit }) => {
		return (
			<div className="input-group">
				<label htmlFor={label}>{title}</label>
				<div style={{ display: "flex" }}>
					<input
						type="number"
						id={label}
						value={inputs[label]}
						step={0.01}
						onChange={(e) =>
							setInputs({
								...inputs,
								[label]: parseFloat(e.target.value),
							})
						}
						required
					/>
					<div className={unit ? "unit" : "currency-symbol"}>
						{unit === "" ? getCurrencySymbol(currencyType) : unit}
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="mhr-container">
			<hr style={{ marginTop: "20px", width: "97%", marginLeft: "20px" }}></hr>
			<div className="input-container">
				<form
					key={showDialog ? "form-open" : "form-closed"}
					onSubmit={handleSubmit}
					name="mhr"
				>
					<div className="fixedCost-container">
						<div className="fixedCost-heading">Fixed Costs</div>
						<div className="fixedcosts">
							{fixedCostsArray.map((input, index) => {
								return (
									<>
										{InputComponent({
											key: index,
											label: input.label,
											title: input.title,
											unit: input.unit,
										})}
									</>
								);
							})}
						</div>
					</div>

					<hr></hr>

					<div className="operatingCost-container">
						<div className="operatingcost-heading">Operating Costs</div>
						<div className="operating-costs">
							{operatingCostsArray.map((input, index) => {
								return (
									<>
										{InputComponent({
											key: index,
											label: input.label,
											title: input.title,
											unit: input.unit,
										})}
									</>
								);
							})}
						</div>
					</div>

					<hr></hr>

					{/* Dialog box to show the Total MHR cost*/}
					{showDialog && (
						<div className="dialog-overlay">
							<div className="dialog-box">
								<div className="content">
									<p style={{ fontWeight: "bold" }}>
										Total MHR Cost:
										<b style={{ color: "green" }}>
											{" " +
												getCurrencySymbol(currencyType) +
												" " +
												results.totalCost}
										</b>
									</p>
									<button onClick={handleCloseDialog} className="close-button">
										<MdClose size={20} />
									</button>
								</div>
							</div>
						</div>
					)}

					<button type="submit" className="calculate">
						Calculate MHR
					</button>
				</form>
			</div>
		</div>
	);
};

export default Mhr;
