import { useDispatch, useSelector } from "react-redux";
import { setFolderOpen } from "../../Store/Slice/stepperSlice";
import {
	MdFolder,
	MdSubdirectoryArrowRight,
	MdOutlineArrowRight,
	MdInsertDriveFile,
} from "react-icons/md";
import "./FolderDropdown.css";
import { trimPartName } from "../../Utils/utils";

export const FolderDropdown = () => {
	const dispatch = useDispatch();
	const jobName = useSelector((state) => state.stepperSlice?.job);
	const folderOpen = useSelector((state) => state.stepperSlice.folderOpen);
	const selectedFiles = useSelector(
		(state) => state.stepperSlice?.job?.materials
	);

	const handleFolderToggle = () => {
		// Toggle folder open/close state

		dispatch(setFolderOpen(!folderOpen));
	};

	return (
		<>
			<div
				className="folder-container"
				onClick={handleFolderToggle}
			>
				<span className="folder-toggle">
					<MdOutlineArrowRight
						size={25}
						style={{
							transform: `${folderOpen ? "rotate(90deg)" : "rotate(0deg)"}`,
							transition: "200ms",
						}}
					/>
				</span>
				<MdFolder
					className="dir-btn"
					size={20}
				/>
				<span
					className="folder-name"
					style={{
						color: `${jobName.job_name.length ? "black" : "#bfbfbf"}`,
						fontWeight: `${jobName.job_name.length ? 500 : 600}`,
						textOverflow: "ellipsis" /* enables ellipsis */,
						whiteSpace: "nowrap" /* keeps the text in a single line */,
						overflow:
							"hidden" /* keeps the element from overflowing its parent */,
					}}
				>
					{jobName.job_name.length
						? trimPartName(jobName.job_name)
						: "Job Name"}
				</span>
			</div>

			<div
				className={`folder-content ${folderOpen ? "open" : ""}`}
				style={{ display: folderOpen ? "block" : "none" }}
			>
				{selectedFiles?.map((card, index) => {
					//Creating unique parts array from builds
					const uniqueArrayOfParts = [
						...new Set(
							card.parts?.map((part) => {
								return part.name;
							})
						),
					];
					return (
						<>
							<div
								key={card.id}
								style={{
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
									padding: "5px 10px ",
									background: "lightgray",
								}}
							>
								{card.material}
							</div>
							<div key={card.id}>
								{uniqueArrayOfParts.map((file, index1) => (
									<div
										key={index1}
										className="file-item"
									>
										<MdSubdirectoryArrowRight />
										<MdInsertDriveFile />
										<span>{trimPartName(file)}</span>
									</div>
								))}
							</div>
						</>
					);
				})}
			</div>
		</>
	);
};
