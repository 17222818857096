// ErrorBoundaryPartRenderer.js
import React, { Component } from "react";

class ErrorBoundaryPartRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, errorInfo) {
		console.error("Error in PartRenderer component:", error);
		this.setState({ hasError: true });
	}
	handleCloseClick = () => {
		this.setState({ hasError: false });
		if (this.props.onClose) {
			this.props.onClose(); // Call the onClose callback
		}
	};

	render() {
		if (this.state.hasError) {
			return (
				<div className="error-popup">
					<div className="error-message">
						<h2>Error occurred in Renderer component.</h2>

						<p>An error occurred while rendering.</p>
						<button
							className="close-button"
							onClick={this.handleCloseClick}
						>
							Close
						</button>
					</div>
				</div>
			);
		}
		return this.props.children;
	}
}

export default ErrorBoundaryPartRenderer;
