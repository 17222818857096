// import { useEffect, useState } from "react";
// import * as THREE from "three";
// import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
// import { MdClose, MdFullscreen } from "react-icons/md";

// const BuildRender = ({
// 	buildModels,
// 	length,
// 	width,
// 	handleFullScreen,
// 	handleModalToggle,
// }) => {
// 	console.log("Build models", buildModels);
// 	console.log("Length models", length);
// 	console.log("Width models", width);

// 	useEffect(() => {
// 		// Create a scene
// 		const scene = new THREE.Scene();

// 		// Set background color
// 		scene.background = new THREE.Color(0xeeeeee);
// 		console.log("Scene", scene);

// 		// Create a camera
// 		const camera = new THREE.PerspectiveCamera(
// 			75,
// 			window.innerWidth / window.innerHeight,
// 			0.1,
// 			10000
// 		);
// 		camera.position.set(100, 100, 100);
// 		camera.lookAt(new THREE.Vector3(0, 0, 0));
// 		console.log("Camera", camera);

// 		const canvascontainer = document.querySelector(".threejs-model-container");

// 		// Create a WebGLRenderer
// 		const renderer = new THREE.WebGLRenderer({ antialias: true });
// 		// renderer.setSize(window.innerWidth, window.innerHeight);
// 		renderer.setSize(750, 400);
// 		renderer.shadowMap.enabled = true;
// 		renderer.shadowMap.type = THREE.PCFSoftShadowMap;

// 		// Append renderer to the DOM
// 		if (canvascontainer) {
// 			canvascontainer.appendChild(renderer.domElement);
// 		}
// 		// document.body.appendChild(renderer.domElement);
// 		console.log("Renderer", renderer);

// 		// Load ground texture
// 		const textureLoader = new THREE.TextureLoader();
// 		textureLoader.load(
// 			"/baseplate.jpg",
// 			(texture) => {
// 				// Create ground plane
// 				const groundPlaneGeometry = new THREE.BoxGeometry(width, 10, length); // Width, height, depth
// 				const groundPlaneMaterial = new THREE.MeshBasicMaterial({
// 					map: texture,
// 					side: THREE.DoubleSide,
// 				});
// 				const groundPlane = new THREE.Mesh(
// 					groundPlaneGeometry,
// 					groundPlaneMaterial
// 				);
// 				groundPlane.position.set(0, 0, -30);
// 				groundPlane.receiveShadow = true;
// 				scene.add(groundPlane);
// 			},
// 			(error) => {
// 				console.error("Error loading ground texture:", error);
// 			}
// 		);

// 		// Create OrbitControls
// 		const controls = new OrbitControls(camera, renderer.domElement);

// 		// Load STL file
// 		const loader = new STLLoader();
// 		// loader.load(name[0].link, (geometry) => {
// 		loader.load(buildModels[0].link, (geometry) => {
// 			console.log("Inside loader");
// 			console.log("Geometry", geometry);
// 			// Create a mesh with the loaded geometry
// 			const hasVertexColors = geometry.attributes.color !== undefined;

// 			let material;

// 			if (hasVertexColors) {
// 				// If the geometry has vertex colors, use MeshBasicMaterial
// 				material = new THREE.MeshBasicMaterial({
// 					// vertexColors: THREE.VertexColors,
// 				});
// 				material.side = THREE.DoubleSide;
// 			} else {
// 				// If there are no vertex colors, use MeshStandardMaterial
// 				material = new THREE.MeshStandardMaterial({
// 					// color: 0xc8cbcb, // Set your desired color
// 					// color: 0x666363,
// 					color: 0x999999,
// 					roughness: 0.1,
// 					metalness: 0.1,
// 				});
// 				material.side = THREE.DoubleSide;
// 			}

// 			const mesh = new THREE.Mesh(geometry, material);
// 			mesh.position.set(
// 				buildModels[0].coordinates[0] - length / 2,
// 				buildModels[0].coordinates[1] - width / 2
// 			);
// 			mesh.rotation.set(
// 				buildModels[0].orientation[0],
// 				buildModels[0].orientation[1],
// 				buildModels[0].orientation[2]
// 			);
// 			mesh.castShadow = true;
// 			geometry.center();
// 			scene.add(mesh);
// 			console.log("mesh added to scene");
// 		});

// 		// const ambientLight = new THREE.AmbientLight();
// 		// scene.add(ambientLight);

// 		const spotLight = new THREE.SpotLight();
// 		spotLight.position.set(100, 100, 0);
// 		spotLight.castShadow = true;
// 		spotLight.distance = 150;
// 		spotLight.angle = Math.PI / 4;
// 		spotLight.shadow.mapSize.width = 520; // Shadow map width
// 		spotLight.shadow.mapSize.height = 520; // Shadow map height
// 		spotLight.shadow.camera.near = 10; // Shadow camera near plane
// 		spotLight.shadow.camera.far = 10; // Shadow camera far plane
// 		scene.add(spotLight);

// 		// const spotLightHelper = new THREE.SpotLightHelper(spotLight);
// 		// scene.add(spotLightHelper);

// 		const spotLight1 = new THREE.SpotLight();
// 		spotLight1.position.set(-100, -100, 0);
// 		scene.add(spotLight1);

// 		const spotLight2 = new THREE.SpotLight();
// 		spotLight2.position.set(0, 0, 200);
// 		scene.add(spotLight2);

// 		// Animation loop
// 		const animate = () => {
// 			requestAnimationFrame(animate);
// 			controls.update();
// 			renderer.render(scene, camera);
// 		};

// 		animate();

// 		// Clean up
// 		return () => {
// 			// Remove renderer from the DOM
// 			// document.body.removeChild(renderer.domElement);
// 			canvascontainer.removeChild(renderer.domElement);
// 		};
// 	}, [buildModels[0].link]);

// 	return (
// 		<>
// 			<div id="modal-renderer" className="threejs-model-container">
// 				<button
// 					style={{
// 						position: "absolute",
// 						right: "0.6rem",
// 						top: "0.6rem",
// 						border: "none",
// 						background: "transparent",
// 					}}
// 					onClick={handleModalToggle}
// 				>
// 					<MdClose size={25} fill="black" />
// 				</button>

// 				<button
// 					style={{
// 						zIndex: "1000",
// 						position: "absolute",
// 						right: "3rem",
// 						top: "0.6rem",
// 						color: "white",
// 						border: "none",
// 						background: "transparent",
// 					}}
// 					onClick={handleFullScreen}
// 				>
// 					<MdFullscreen size={25} fill="black" />
// 				</button>
// 			</div>
// 		</>
// 	);
// };

// export default BuildRender;
import React, { useEffect, useState } from "react";
import { MdClose, MdFullscreen } from "react-icons/md";
import * as THREE from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

// Calculate the bounding box of a geometry
// const CalculateBoundingBox = (stlfile) => {
// 	const val = stlfile.boundingBox;
// 	console.log(val);
// 	return val;
// };

// Align the position of the geometry to the plate
const alignToPlate = (position, boundingBox, groundHeight) => {
	if (!position || position.length !== 3 || !boundingBox) return;
	console.log(boundingBox);
	const newPosition = [...position];
	const geometryHeight = boundingBox.max.y - boundingBox.min.y;
	newPosition[1] = geometryHeight / 2; // Adjust the position along the y-axis
	console.log(position, newPosition);
	return newPosition;
};

// FUNCTIONAL COMPONENT
const ModelRender = ({
	buildModels,
	length,
	width,
	handleModalToggle,
	handleFullScreen,
}) => {
	const [loadingProgress, setLoadingProgress] = useState({
		status: "fulfilled",
		progress: 0,
	});

	useEffect(() => {
		// Create a scene
		const scene = new THREE.Scene();
		scene.background = new THREE.Color(0xeeeeee);

		// Create a camera
		const camera = new THREE.PerspectiveCamera(
			70,
			window.innerWidth / window.innerHeight,
			1,
			10000
		);
		camera.position.set(100, 100, 100);
		camera.lookAt(new THREE.Vector3(0, 0, 0));

		// Create a renderer
		const renderer = new THREE.WebGLRenderer({ antialias: true });
		renderer.setPixelRatio(window.devicePixelRatio);
		renderer.setSize(750, 400);
		renderer.shadowMap.enabled = true;
		renderer.shadowMap.type = THREE.PCFSoftShadowMap;
		document
			.querySelector("#modal-renderer.threejs-model-container")
			.appendChild(renderer.domElement);

		// Load buildModels
		buildModels.forEach((model) => {
			console.log(model);
			const loader = new STLLoader();
			loader.load(
				model.link,
				(geometry) => {
					const material = new THREE.MeshStandardMaterial({
						color: 0x999999,
						roughness: 0.1,
						metalness: 0.1,
						side: THREE.DoubleSide,
					});
					const mesh = new THREE.Mesh(geometry, material);
					mesh.position.set(
						model.coordinates[0] - length / 2,
						0,
						model.coordinates[1] - width / 2
					);

					// Calculate bounding box and align position
					// const boundingBox = CalculateBoundingBox(mesh);
					const boundingBox = new THREE.Box3().setFromObject(mesh);
					const x = new THREE.Vector3(1, 0, 0);
					mesh.rotateOnWorldAxis(x, -Math.PI / 2);
					console.log(boundingBox);

					// const newPosition = alignToPlate(
					// 	mesh.position.toArray(),
					// 	boundingBox
					// );
					// mesh.position.fromArray(newPosition);
					mesh.position.set(
						...alignToPlate(mesh.position.toArray(), boundingBox, 10)
					);
					mesh.castShadow = true;
					scene.add(mesh);
				},
				(xhr) => {
					if (xhr.total) {
						const progress = xhr.total ? (xhr.loaded / xhr.total) * 100 : 0;
						setLoadingProgress((prev) => {
							return { ...prev, progress: progress };
						});
					}
				},
				// Error callback
				(error) => {
					console.error("Error loading object:", error);
				}
			);
		});

		// Load ground texture
		const textureLoader = new THREE.TextureLoader();
		textureLoader.load(
			"/baseplate.jpg",
			(texture) => {
				const groundPlaneGeometry = new THREE.BoxGeometry(length, 10, width);
				const groundPlaneMaterial = new THREE.MeshPhysicalMaterial({
					map: texture,
					side: THREE.DoubleSide,
				});
				const groundPlane = new THREE.Mesh(
					groundPlaneGeometry,
					groundPlaneMaterial
				);
				groundPlane.position.set(0, 0, 0);
				groundPlane.receiveShadow = true;
				scene.add(groundPlane);
			},
			(error) => {
				console.error("Error loading ground texture:", error);
			}
		);

		//Ambient Light
		const ambientLight = new THREE.AmbientLight();
		scene.add(ambientLight);

		// Spot lights
		const spotLight = new THREE.SpotLight();
		spotLight.position.set(100, 100, 0);
		spotLight.castShadow = true;
		spotLight.distance = 600;
		spotLight.angle = Math.PI / 2;
		spotLight.shadow.mapSize.width = 520;
		spotLight.shadow.mapSize.height = 520;
		spotLight.shadow.camera.near = 10;
		spotLight.shadow.camera.far = 10;
		spotLight.shadow.camera.fov = 30;
		scene.add(spotLight);

		const spotLight1 = new THREE.SpotLight();
		spotLight1.position.set(-100, -100, 0);
		spotLight1.distance = 600;
		scene.add(spotLight1);

		const spotLight2 = new THREE.SpotLight();
		spotLight2.position.set(0, 0, 100);
		spotLight2.distance = 600;
		scene.add(spotLight2);

		// Add camera controls
		const controls = new OrbitControls(camera, renderer.domElement);

		// Animation loop
		const animate = () => {
			requestAnimationFrame(animate);
			controls.update();
			renderer.render(scene, camera);
		};

		animate();

		// Cleanup
		return () => {
			const ele = document.querySelector(
				"#modal-renderer.threejs-model-container"
			);

			if (ele) {
				ele.removeChild(renderer.domElement);
			} else {
				console.log("Erro removing", ele);
			}
		};
	}, [buildModels, length, width]);

	return (
		<div id="modal-renderer" className="threejs-model-container">
			{/* Part Renderer container */}
			<div
				style={{
					position: "absolute",
					top: "0.6rem",
					left: "1rem",
					color: "gray",
					display: "flex",
					fontWeight: "bolder",
				}}
			>
				<p>{`Plate size : ${length} x ${width}`}</p>
			</div>

			{/* Show the below modal until the part is loaded */}
			<div>
				{loadingProgress.progress < 100 && (
					<div
						style={{
							position: "absolute",
							top: 200,
							left: 250,
							width: "30%",
							height: "4%",
							backgroundColor: "white",
							textAlign: "center",
							borderRadius: "10px",
						}}
					>
						<div
							className="progress"
							style={{
								width: `${loadingProgress.progress}%`,
								top: "20px",
								height: "16px",
								backgroundImage:
									"linear-gradient(to right,#9c9b9b,#bbbbbb,#cccccc,#dddddd,#eeeeee)",
								borderRadius: "10px",
							}}
						/>
						<div
							style={{
								position: "absolute",
								top: "1px",
								left: "45%",
								fontSize: "10px",
							}}
						>
							{loadingProgress.progress.toFixed(0)} %
						</div>
					</div>
				)}
			</div>

			{/* Full Screen */}
			{loadingProgress.progress >= 100 ? (
				<button
					style={{
						zIndex: "1000",
						position: "absolute",
						right: "3rem",
						top: "0.6rem",
						color: "white",
						border: "none",
						background: "transparent",
					}}
					onClick={handleFullScreen}
				>
					<MdFullscreen size={25} fill="black" />
				</button>
			) : (
				<button
					style={{
						zIndex: "1000",
						position: "absolute",
						right: "3rem",
						top: "0.6rem",
						color: "white",
						border: "none",
						background: "transparent",
						pointerEvents: "none", // Disable clicking on the button
					}}
				>
					<MdFullscreen size={25} fill="gray" />
				</button>
			)}

			{/* Close the renderer */}
			<button
				onClick={handleModalToggle}
				style={{
					position: "absolute",
					right: "0.6rem",
					top: "0.6rem",
					border: "none",
					background: "transparent",
				}}
			>
				<MdClose size={25} fill="black" />
			</button>

			<div
				style={{
					position: "absolute",
					bottom: "0.6rem",
					right: "1rem",
					color: "gray",
				}}
			>
				<p>Powered by Intech Additive Solutions Pvt Ltd</p>
			</div>
		</div>
	);
};

export default ModelRender;
