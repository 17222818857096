import { useEffect, useRef, useState } from "react";
import "./partRenderer.css";

import { MdClose, MdFullscreen } from "react-icons/md";

import * as THREE from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

const PartRender = ({
	selectedPart,
	handleFullScreen,
	handlePartModalToggle,
}) => {
	console.log("Selected Part", selectedPart);
	const [loadingProgress, setLoadingProgress] = useState({
		status: "fulfilled",
		progress: 0,
	});

	// const [loadingProgress, setLoadingProgress] = useState(0);

	const containerRef = useRef();
	// const aborterRef = useRef();
	// let xhr;

	useEffect(() => {
		// Create a scene
		const scene = new THREE.Scene();

		// Set background color
		scene.background = new THREE.Color(0xeeeeee);

		// Create a camera
		const camera = new THREE.PerspectiveCamera(
			75,
			window.innerWidth / window.innerHeight,
			0.1,
			10000
		);
		camera.position.set(100, 100, 100);
		camera.lookAt(new THREE.Vector3(0, 0, 0));

		// Create a WebGLRenderer
		const renderer = new THREE.WebGLRenderer({ antialias: true });
		// renderer.setSize(window.innerWidth, window.innerHeight);
		renderer.setSize(750, 400);
		renderer.shadowMap.enabled = true;

		// Append renderer to the DOM
		if (containerRef.current && !containerRef.current[renderer.domElement]) {
			containerRef.current.appendChild(renderer.domElement);
		}
		// document.body.appendChild(renderer.domElement);

		// Create OrbitControls
		const controls = new OrbitControls(camera, renderer.domElement);

		// Load STL file
		const loader = new STLLoader();
		loader.load(
			selectedPart.link,
			//Progress Function
			(geometry) => {
				// console.log(aborterRef.current);
				// Create a mesh with the loaded geometry
				const hasVertexColors = geometry.attributes.color !== undefined;

				let material;

				if (hasVertexColors) {
					// If the geometry has vertex colors, use MeshBasicMaterial
					material = new THREE.MeshBasicMaterial({
						// vertexColors: THREE.VertexColors,
					});
					material.side = THREE.DoubleSide;
				} else {
					// If there are no vertex colors, use MeshStandardMaterial
					material = new THREE.MeshStandardMaterial({
						color: 0xc8cbcb, // Set your desired color
						roughness: 0.1,
						metalness: 0.1,
					});
					material.side = THREE.DoubleSide;
				}

				const mesh = new THREE.Mesh(geometry, material);
				mesh.castShadow = true;
				//geometry.center();
				scene.add(mesh);
			},
			(xhr) => {
				// if (!xhr) xhr = this; // Set xhr reference if it's not set
				// aborterRef.current = xhr;
				// console.log(xhr);
				// aborterRef.current = xhr.target;
				// console.log("total", xhr.total);

				if (xhr.total) {
					const progress = xhr.total ? (xhr.loaded / xhr.total) * 100 : 0;
					setLoadingProgress((prev) => {
						return { ...prev, progress: progress };
					});
					// setLoadingProgress(progress);
				}
			},
			// Error callback
			(error) => {
				console.error("Error loading object:", error);
			}
		);

		//Spot Light
		const spotLight = new THREE.SpotLight();
		spotLight.position.set(0, 100, -100);
		scene.add(spotLight);

		const spotLight1 = new THREE.SpotLight();
		spotLight1.position.set(0, -100, 100);
		scene.add(spotLight1);

		const spotLight2 = new THREE.SpotLight();
		spotLight2.position.set(50, 100, -100);
		scene.add(spotLight2);

		// Animation loop
		const animate = () => {
			requestAnimationFrame(animate);
			controls.update();
			renderer.render(scene, camera);
		};

		animate();

		// Clean up
		return () => {
			// Remove renderer from the DOM
			// console.log(aborterRef.current);
			// aborterRef.current?.abort();

			const container = containerRef.current;
			if (container) {
				container.removeChild(renderer.domElement);
			}
		};
	}, [selectedPart.link]);

	console.log("Loading", loadingProgress.progress);

	// const handleCloseButtonClick = () => {
	// 	if (aborterRef.current) {
	// 		aborterRef.current.abort();
	// 	}
	// 	handlePartModalToggle();
	// };

	return (
		<>
			<div className="threejs-main-container">
				{/* Show the below modal until the part is loaded */}
				<div>
					{loadingProgress.progress < 100 && (
						<div
							style={{
								position: "absolute",
								top: 200,
								left: 250,
								width: "30%",
								height: "4%",
								backgroundColor: "white",
								textAlign: "center",
								borderRadius: "10px",
							}}
						>
							<div
								className="progress"
								style={{
									width: `${loadingProgress.progress}%`,
									top: "20px",
									height: "16px",
									backgroundImage:
										"linear-gradient(to right,#9c9b9b,#bbbbbb,#cccccc,#dddddd,#eeeeee)",
									borderRadius: "10px",
								}}
							/>
							<div
								style={{
									position: "absolute",
									top: "1px",
									left: "45%",
									fontSize: "10px",
								}}
							>
								{loadingProgress.progress.toFixed(0)} %
							</div>
						</div>
					)}
				</div>

				{/* Part Renderer container */}
				<div
					style={{
						position: "absolute",
						top: "0.6rem",
						left: "1rem",
						color: "gray",
						display: "flex",
						fontWeight: "bolder",
					}}
				>
					<p>
						Part Name: <span>{selectedPart.name}</span>
					</p>
				</div>

				<button
					style={{
						position: "absolute",
						right: "0.6rem",
						top: "0.6rem",
						border: "none",
						background: "transparent",
					}}
					onClick={handlePartModalToggle}
				>
					<MdClose size={25} fill="black" />
				</button>

				{/* <button
					style={{
						zIndex: "1000",
						position: "absolute",
						right: "3rem",
						top: "0.6rem",
						color: "white",
						border: "none",
						background: "transparent",
					}}
					onClick={handleFullScreen}
				>
					<MdFullscreen size={25} fill="black" />
				</button> */}

				{/* Full Screen */}
				{loadingProgress.progress >= 100 ? (
					<button
						style={{
							zIndex: "1000",
							position: "absolute",
							right: "3rem",
							top: "0.6rem",
							color: "white",
							border: "none",
							background: "transparent",
						}}
						onClick={handleFullScreen}
					>
						<MdFullscreen size={25} fill="black" />
					</button>
				) : (
					<button
						style={{
							zIndex: "1000",
							position: "absolute",
							right: "3rem",
							top: "0.6rem",
							color: "white",
							border: "none",
							background: "transparent",
							pointerEvents: "none", // Disable clicking on the button
						}}
					>
						<MdFullscreen size={25} fill="gray" />
					</button>
				)}

				{/* Renderer */}
				<div ref={containerRef}></div>

				<div
					style={{
						position: "absolute",
						bottom: "0.6rem",
						right: "1rem",
						color: "gray",
					}}
				>
					<p>Powered by Intech Additive Solutions Pvt Ltd</p>
				</div>
			</div>
		</>
	);
};

export default PartRender;
