import { useState } from "react";
import "./incompletejobs.css";
import axios from "../../api/axios";
import { Loader } from "../Loader/Loader";
import { Popup } from "../Popup/Popup";
import { LoadingDots } from "../LoadingDots/LoadingDots";

//Library Imports
import {
  MdPlayArrow,
  MdNavigateBefore,
  MdNavigateNext,
  MdDelete,
} from "react-icons/md";

//Redux Imports
import { useDispatch, useSelector } from "react-redux";
import { deleteJob } from "../../Store/Slice/homeSlice";
import {
  continueIncompleteJob,
  setErrors,
  setIsDrawerOpen,
} from "../../Store/Slice/stepperSlice";
import { CurrencyConverter } from "../../Utils/utils";

export const IncompleteJobs = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState({
    id: null,
    name: null,
  });
  const [incompletJobStatus, setIncompleteJobStatus] = useState({
    status: "",
    id: "",
  });

  const dispatch = useDispatch();
  const jobList = useSelector((state) => state.homeSlice.homeData);
  const isDrawerOpen = useSelector(
    (state) => state.stepperSlice.drawer.isDrawerOpen,
  );
  const searchQuery = useSelector(
    (state) => state.stepperSlice.search?.incompleteJobSearchQuery,
  );
  const status = useSelector((state) => state.homeSlice.status);
  const authCurrencyType = useSelector(
    (state) => state.settingsSlice.currencyType,
  );

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  // Pagination state
  const itemsPerPage = 4; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate pagination boundaries
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentJobs = jobList
    ?.filter((job) => job.status === false)
    .slice(indexOfFirstItem, indexOfLastItem);

  const validateAndSetErrors = (res) => {
    console.log("vl----------------------------");
    // if (!res.currentStep || !res.job.job_name || !res.job) return;
    //Stting job name error
    if (res.job.job_name.trim() === "") {
      dispatch(
        setErrors({
          cardNumber: -1, // Assuming the job name error is not related to a specific card
          name: "jobName",
          errorMessage: "Job Name can't be blank",
        }),
      );
    } else {
      dispatch(
        setErrors({
          cardNumber: -1,
          name: "jobName",
          errorMessage: "", // Clear the error message if the job name is not empty
        }),
      );
    }

    //setting card errors
    const keysToCheck = [
      "material",
      "cost",
      "currency",
      "layer_thickness",
      "file",
    ];

    for (let i = 0; i < res.job.materials.length; i++) {
      // Check if parts list is empty in materials
      //   const material = res.job.materials[i];
      //   if (!material.parts || material.parts.length === 0) {
      //     dispatch(
      //       setErrors({
      //         cardNumber: i,
      //         name: "file",
      //         errorMessage: "File error: Parts list is empty",
      //       })
      //     );
      //   }
      //   else {
      //     // Set the file error as empty if parts list is not empty
      //     dispatch(
      //       setErrors({
      //         cardNumber: i,
      //         name: "file",
      //         errorMessage: "",
      //       })
      //     );
      //   }

      //for every card
      for (const key of keysToCheck) {
        //setting error as empty for ever field in card
        // if (key in res.job.materials[i]) {
        // console.log("check key-", res.job.materials[i][key]);
        // console.log("check key-", key);
        if (key === "cost") {
          if (res.job.materials[i][key] === "0.00") {
            continue;
          }
        }

        if (key === "file") {
          if (res.job.materials[i]["parts"].length !== 0) {
            dispatch(setErrors({ cardNumber: i, name: key, errorMessage: "" }));
          } else continue;
        }

        if (res.job.materials[i][key] !== "") {
          dispatch(
            setErrors({
              cardNumber: i,
              name: key,
              // value: "",
              errorMessage: "",
            }),
          );
        }
      }
    }
  };

  const handleContinueClick = async (job) => {
    // MAKE AN API CALL TO GET THE JOB, CURRENTSTEP, STATUS AND SET THE STEPPER SLICE BEFORE THAT CLEAR THE STEPPER
    // SLICE BY DISPATCHING CREAR STEPPER REDUCER dispatch(clearStepper());
    setIncompleteJobStatus({ status: "pending", id: job.id });

    console.log(job.id);

    await axios
      .get(`/api/v1/job/${job.id}`)
      .then((response) => {
        // Handle successful response
        console.log("Data:", response.data);

        setIncompleteJobStatus({ status: "fulfilled", id: job.id });

        // const res = {
        //   currentStep: 0,
        //   status: false,
        //   job: {
        //     job_name: "jobss",
        //     id: "0034f9ad-26b7-457b-b31d-652f672eebf8",
        //     currencyType: 'USD', //Proposed Change
        //     materials: [
        //       {
        //         id: "6c4aee76-27c1-4ad7-a0d6-359d3677e34c",
        //         material: "Aluminium",
        //         cost: "2323",
        //         currency: "USD",
        //         layer_thickness: "50",
        //         parts: [
        //           {
        //             name: "Step (1).stl",
        //             id: "d5e4902a-714b-40af-8f88-258181c6b82b",
        //             qty: 1,
        //           },
        //         ],
        //       },
        //       {
        //         id: "4bde5538-e9b2-49a3-83f6-b03331a1cb43",
        //         material: "Silver",
        //       },
        //     ],
        //

        // const convertCurrencyInJob = (job) => {
        //   let newJob = job;
        //
        //   if (job.currencyType !== authCurrencyType) {
        //     newJob.materials.map((material) =>
        //       CurrencyConverter(material.cost),
        //     );
        //   }
        //
        //   console.log(newJob);
        //
        //   return newJob;
        // };

        const res = {
          currentStep: response.data.currentStep,
          status: response.data.status,
          job: response.data.job,
        };

        dispatch(continueIncompleteJob(res));
        // loop over the and dispatch to add new card so that it's errors are set
        //may need to make vlidate function here and set the errors by checking what is there in the job
        console.log("ress---", response.data);
        // validate and set error only for the fields that are present in response.
        // for other fields don't set errors since on clicking next other validate function in stepper.js checks if the field is present or not and set's errors for that field.
        validateAndSetErrors(response.data);

        dispatch(setIsDrawerOpen(!isDrawerOpen));
      })
      .catch((error) => {
        setIncompleteJobStatus({ status: "rejected", id: job.id });

        console.error("Error:", error);
      });
  };

  const handleDeleteJob = (jobID) => {
    dispatch(deleteJob(jobID));
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(
    jobList?.filter((job) => job.status !== true)?.length / itemsPerPage,
  );

  const ContinueIncompleteJobStatusComponent = ({ jobId }) => {
    if (jobId === incompletJobStatus.id) {
      switch (incompletJobStatus.status) {
        case "pending":
          return (
            <div style={{ padding: "6px 1.65rem" }}>
              <LoadingDots />
            </div>
          );

        case "fulfilled":
          return (
            <>
              <MdPlayArrow className="drop-link" /> Continue
            </>
          );

        case "rejected":
          return (
            <div style={{ color: "red", padding: "6px 1.65rem" }}>Error</div>
          );

        default:
          return (
            <>
              <MdPlayArrow className="drop-link" /> Continue
            </>
          );
      }
    } else {
      return (
        <>
          <MdPlayArrow className="drop-link" />
          Continue
        </>
      );
    }
  };

  return (
    <div className="Completed-jobs-list-container">
      {status === "loading" ? (
        <div
          style={{
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : currentJobs === undefined ? (
        <></>
      ) : currentJobs?.length ? (
        <>
          {/* {currentJobs?.map((job, index) => ( */}
          {currentJobs
            ?.filter((job) =>
              job.name.toLowerCase().includes(searchQuery.toLowerCase()),
            )
            .map((job) => (
              <>
                <div key={job.id} className="jobs-list-item">
                  {job.name}
                  <div className="list-icon-container">
                    <div>Date: {job.updatedAt.split("T")[0]}</div>

                    <div>
                      <MdDelete
                        size={30}
                        className="delete-list"
                        onClick={() => {
                          setSelectedJob({ id: job.id, name: job.name });
                          togglePopup();
                        }}
                      />
                    </div>

                    <div className="dropdown">
                      <button
                        onClick={() => handleContinueClick(job)}
                        className="normal-black-button"
                      >
                        <ContinueIncompleteJobStatusComponent jobId={job.id} />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ))}

          {isPopupOpen && (
            <Popup
              onClose={togglePopup}
              handleSave={() => {
                handleDeleteJob(selectedJob.id);
                togglePopup();
              }}
              handleCancel={togglePopup}
              triggeredBy={"jobs"}
              jobName={selectedJob.name}
            />
          )}
          {/* Pagination */}
          <div className="pagination">
            <button
              className="pagination-button"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <MdNavigateBefore />
            </button>
            {Array.from({ length: totalPages }, (_, index) => index + 1).map(
              (pageNumber) => (
                <button
                  key={pageNumber}
                  className={`pagination-button ${
                    pageNumber === currentPage ? "active" : ""
                  }`}
                  onClick={() => paginate(pageNumber)}
                >
                  {pageNumber}
                </button>
              ),
            )}
            <button
              className="pagination-button"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <MdNavigateNext />
            </button>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "lightgrey",
            fontSize: "1.5rem",
            margin: "1rem",
          }}
        >
          <p>No jobs in progress. Get started by adding a new job!</p>
          <ol
            style={{
              fontSize: "1.2rem",
              margin: "3rem 3rem 3rem 5rem",
            }}
          >
            <li>Create job: Click the 'Create Job' button to begin.</li>
            <li>Add quantity: Specify the quantity or details of the job.</li>
            <li>Review: Double-check the job information for accuracy.</li>
            <li>
              Invoice: Complete the job creation process by generating an
              invoice.
            </li>
          </ol>
        </div>
      )}
    </div>
  );
};
