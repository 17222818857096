export const trimPartName = (partName, nameLimit = 10) => {
  if (partName.length <= nameLimit) {
    return partName;
  }

  const ellipsis = "...";
  const nameWithoutExtension = partName;
  let extension = "";
  if (partName.split(".").length > 1) {
    extension = partName
      .split(".")
      .slice(partName.split(".").length - 1)
      .join(".");
  }
  const trimmedName = nameWithoutExtension.substring(0, nameLimit - 4);

  return trimmedName + ellipsis + extension;
};

export const CurrencyConverter = (currentCurrency, newCurrency, amount) => {
  // Define conversion rates for each currency pair
  const conversionRates = {
    INR: { USD: 0.012, EUR: 0.011, YEN: 1.77 },
    USD: { INR: 83.33, EUR: 0.91, YEN: 113.33 },
    EUR: { INR: 90.91, USD: 1.1, YEN: 124.32 },
    YEN: { INR: 0.56, USD: 0.0088, EUR: 0.008 },
  };

  // If the currencies are the same, return the original amount
  if (currentCurrency === newCurrency) {
    return amount;
  }

  // If the conversion rates are not available, return null
  if (
    !conversionRates[currentCurrency] ||
    !conversionRates[currentCurrency][newCurrency]
  ) {
    return null;
  }

  // Calculate the converted amount
  const conversionRate = conversionRates[currentCurrency][newCurrency];
  const convertedAmount = amount * conversionRate;

  return convertedAmount.toFixed(2);
};
