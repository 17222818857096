import { useEffect, useState } from "react";
import "./home.css";
import Drawer from "../Drawer";
import { deleteJobFromS3 } from "../AWS/awsService";
import { Tabs, CompletedJobs, IncompleteJobs, Popup } from "../Components";

//Library Imports
import { Tooltip as ReactTooltip } from "react-tooltip";
import { MdAdd, MdInfo, MdOutlineInfo } from "react-icons/md";

//Redux Imports
import { fetchJobs } from "../Store/Slice/homeSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStepper,
  deleteEmptyCard,
  postJob,
  setErrors,
  setIsDrawerOpen,
  setIsPopupOpen,
} from "../Store/Slice/stepperSlice";

const Home = () => {
  const userUnq = useSelector((state) => state.authSlice?.user);
  const [isHovered, setIsHovered] = useState(false);

  const isDrawerOpen = useSelector(
    (state) => state.stepperSlice.drawer.isDrawerOpen,
  );
  const currenStep = useSelector((state) => state.stepperSlice?.currenStep);
  const isPopupOpen = useSelector(
    (state) => state.stepperSlice.drawer.isPopupOpen,
  );
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const jobid = useSelector((state) => state.stepperSlice.job?.id);
  const job = useSelector((state) => state.stepperSlice.job);
  const userID = useSelector((state) => state.authSlice.user?.id);

  const dispatch = useDispatch();

  const toggleDrawer = async () => {
    if (isDrawerOpen) {
      openPopup();
    } else {
      if (isDrawerOpen) await deleteJobFromS3(jobid, userID); // Delete the job from S3

      // dispatch(clearStepper());
      dispatch(clearStepper());
      dispatch(setIsDrawerOpen(!isDrawerOpen));
    }
  };

  const openPopup = () => {
    dispatch(setIsPopupOpen(true));
  };

  const closePopup = () => {
    dispatch(setIsPopupOpen(false));
  };

  const handleSave = async () => {
    if (!job.job_name) {
      dispatch(
        setErrors({
          cardNumber: -1, // Assuming the job name error is not related to a specific card
          name: "jobName",
          errorMessage: "Job Name can't be blank",
        }),
      );
      closePopup();
      return;
    }
    // try {
    setIsSaving(true); // Start saving process // state.job.materials.map((material) => { //     if ( //         Object.keys(material).length === 1 && //         Object.keys(material)[0] === "id" //     ) { //         dispatch(deleteCard({ id: material.id })); //     } // });
    dispatch(deleteEmptyCard()); // state.job.materials.map((material) => { //     if ( //         Object.keys(material).length === 1 && //         Object.keys(material)[0] === "id" //     ) { //         dispatch(deleteCard({ id: material.id })); //     } // });

    console.log("after delete empty card");

    dispatch(postJob(userUnq.id));
    closePopup(); // Close the popup
    //prev was here
    dispatch(setIsDrawerOpen(!isDrawerOpen)); // Open the drawer after a delay // setTimeout(() => { // }, 2000); // 2000 milliseconds delay (2 seconds)
    setIsSaving(false); // End saving process
  };

  const handleCancel = async () => {
    // Perform cancel logic here
    // const jobid = useSelector(state.)
    //clear the stepper slice
    setIsDeleting(true); // Start the saving process

    try {
      if (currenStep === 0 || currenStep === 3)
        await deleteJobFromS3(jobid, userID); // Delete the job from S3
      // dispatch(clearStepper()); // Clear the stepper slice
      closePopup(); // Close the popup
      //prev was here
      dispatch(setIsDrawerOpen(!isDrawerOpen)); // Toggle the drawer
    } catch (error) {
      console.error("Error deleting job:", error);
    } finally {
      setIsDeleting(false); // End the saving process
      dispatch(clearStepper()); // Clear the stepper slice
    }
  };

  const tabs = [
    {
      label: "Completed Jobs",
      content: (
        <div>
          <CompletedJobs />
        </div>
      ),
    },
    {
      label: "Incomplete Jobs",
      content: (
        <div style={{}}>
          <IncompleteJobs />
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchJobs());
  }, [userUnq]);

  return (
    <div className="home-main-container">
      <div className="job-btn-container">
        <h1>Home</h1>
        <div style={{ display: "flex" }}>
          <button className="create-job-btn" onClick={toggleDrawer}>
            <MdAdd size={20} />
            Create Job
          </button>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "5px",
            }}
            data-tooltip-id="home-target"
            data-tooltip-content="Job specifies the total project which includes files,
						quantity, layer thickness and material information of each
						part. The cost of the job specifies the entire cost excluding
						post processing."
            data-tooltip-delay-show="150"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {isHovered ? <MdInfo size={20} /> : <MdOutlineInfo size={20} />}
          </div>

          <ReactTooltip
            id="home-target"
            place="right"
            style={{
              width: "25rem",
            }}
          />
        </div>

        <div className="tabs-list">
          <Tabs tabs={tabs} />
          {/* Pagination */}
        </div>
      </div>

      {/* Drawer */}
      <Drawer isDrawerOpen={isDrawerOpen} handleToggle={toggleDrawer} />

      {/* Popup */}
      {isPopupOpen && (
        <Popup
          onClose={closePopup}
          handleSave={handleSave}
          handleCancel={handleCancel}
          isSaving={isSaving}
          isDeleting={isDeleting}
        />
      )}
    </div>
  );
};

export default Home;
