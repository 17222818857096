import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import axios from "../../api/axios";
import { fetchJobs } from "./homeSlice";

const initialState = {
  currentStep: 0,
  status: false,

  //Status for fetching jobs when clicked on view button
  completedJobStatus: { status: "fulfilled" },

  job: {
    job_name: "",
    id: uuidv4(),
    currencyType: "USD",
    materials: [],
    builds: [],
  },

  errors: [],
  drawer: {
    isDrawerOpen: false,
    isPopupOpen: false,
  },

  stepOneCardDeleteLoader: {
    status: false,
    id: null,
  },

  folderOpen: true,
  buildsLoadingDisable: {
    loading: false,
    hasError: false,
  },

  search: {
    completedJobSearchQuery: "",
    incompleteJobSearchQuery: "",
  },

  uniqueParts: [],
};

const stepperSlice = createSlice({
  name: "stepper",

  initialState,

  reducers: {
    setStepOneCardDeleteLoader: (state, action) => {
      state.stepOneCardDeleteLoader.status = action.payload.status;
      state.stepOneCardDeleteLoader.id = action.payload.id;
    },

    nextStep: (state) => {
      state.currentStep += 1;
    },

    prevStep: (state) => {
      state.currentStep -= 1;
    },

    setJobName: (state, action) => {
      state.job.job_name = action.payload; // Update the job_name field with the provided value
    },

    setIsPopupOpen: (state, action) => {
      state.drawer.isPopupOpen = action.payload;
    },

    setIsDrawerOpen: (state, action) => {
      state.drawer.isDrawerOpen = action.payload;
    },

    clearStepper: (state) => {
      //delete the job from aws s3
      // deleteJobFromS3(state.job.id);
      const init = {
        ...initialState,
        job: { ...initialState.job, id: uuidv4() },
      };
      return init; // Reset the state to the initial state
    },

    continueIncompleteJob: (state, action) => {
      const { currentStep, status, job } = action.payload;
      state.currentStep = currentStep;
      state.status = status;
      state.job = job;
    },

    addCard: (state, action) => {
      const newCard = { id: action.payload.id };
      if (!state.job.materials) {
        state.job.materials = []; // Initialize the array if it's undefined
      }
      state.job.materials.push(newCard);
      state.errors[state.job.materials.length - 1] = {};
    },

    deleteCard: (state, action) => {
      let index = -1;
      state.job.materials = state.job.materials.filter((material) => {
        index++;
        return material.id !== action.payload.id;
      });
      state.errors[index] = {};
    },

    deleteEmptyCard: (state) => {
      state.job.materials = state.job.materials.filter((material) => {
        return !(
          Object.keys(material).length === 1 &&
          Object.keys(material)[0] === "id"
        );
      });
    },

    selectOption: (state, action) => {
      const { cardId, name, value } = action.payload;
      const cardIndex = state.job.materials.findIndex(
        (card) => card.id === cardId,
      );
      if (cardIndex !== -1) {
        state.job.materials[cardIndex][name] = value;
      }
    },

    addUploadedStlFiles: (state, action) => {
      const { cardId, partObj } = action.payload;
      const cardIndex = state.job.materials.findIndex(
        (card) => card.id === cardId,
      );
      if (cardIndex !== -1) {
        console.log("statte----", cardIndex);
        if (!state.job.materials[cardIndex].parts) {
          state.job.materials[cardIndex].parts = []; // Initialize the array if it's undefined
        }
        state.job.materials[cardIndex].parts.push(partObj);
      }
    },

    setUploadingDisable: (state, action) => {
      state.uploadingDisable = action.payload;
    },

    setBackDisable: (state, action) => {
      state.backButtonDisable = action.payload;
    },

    setBuildsLoadingDisable: (state, action) => {
      console.log("disabling...", action.payload);
      const { loading, hasError } = action.payload;

      state.buildsLoadingDisable = { loading: loading, hasError: hasError };
    },

    setErrors: (state, action) => {
      const { cardNumber, name, value, errorMessage } = action.payload;

      if (cardNumber === -1) {
        // Handle error for the job name
        state.jobNameError = errorMessage;
        return;
      }

      let errorText = "";

      if (!errorMessage) {
        //if not getting error message from user create message using errorMessageGenerator
        console.log("err msg --------------------", errorMessage);
        errorText = errorMessageGenerator(name, value, errorMessage);
      } else errorText = errorMessage;
      // console.log("my validate eror--", error);

      state.errors[cardNumber] = {
        ...state.errors[cardNumber],
        [name]: errorText,
      };
    },

    // Step 2 REDUCERS
    addQuantity: (state, action) => {
      const { materialIndex, partIndex, quantity } = action.payload;
      console.log("act-", action);
      state.job.materials[materialIndex].parts[partIndex].qty = quantity;
    },

    deletePart: (state, action) => {
      const { partId, materialId } = action.payload;
      const { materials } = state.job;
      const materialIndex = materials.findIndex(
        (material) => materialId === material.id,
      );
      const partIndex = materials
        .find((material) => material.id === materialId)
        .parts.findIndex((part) => part.id === partId);

      materials[materialIndex].parts.splice(partIndex, 1);
    },

    clearBuilds: (state) => {
      state.job.builds = [];
    },

    clearUniqueParts: (state) => {
      state.uniqueParts = [];
    },

    setFolderOpen: (state, action) => {
      console.log("action folder open", action.payload);
      state.folderOpen = action.payload;
    },

    setJobCost: (state, action) => {
      console.log(action.payload.totalCost);
      state.jobCost = action.payload.totalCost;
    },

    setSearchQuery: (state, action) => {
      state.search.completedJobSearchQuery = action.payload.complete;
      state.search.incompleteJobSearchQuery = action.payload.incomplete;
    },

    setUniqueParts: (state, action) => {
      state.uniqueParts = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(postJob.pending, (state) => {
        // state.status = "saving";
      })

      .addCase(postJob.fulfilled, (state, { payload }) => {
        state.status = true;
      })

      .addCase(postJob.rejected, (state, err) => {
        // state.status = "save failed";
      })

      .addCase(buildRes.pending, (state) => {
        // state.status = "saving";
      })

      .addCase(buildRes.fulfilled, (state, action) => {
        console.log("action", action.payload);
        state.job.builds = action.payload;
      })

      .addCase(buildRes.rejected, (state, err) => {
        // state.status = "save failed";
      })

      .addCase(showCompletedJob.pending, (state, action) => {
        state.completedJobStatus = { status: "pending", id: action.meta.arg };
      })

      .addCase(showCompletedJob.rejected, (state, action) => {
        state.completedJobStatus = { status: "rejected", id: action.meta.arg };
      })

      .addCase(showCompletedJob.fulfilled, (state, action) => {
        const { currentStep, status, job } = action.payload;
        state.completedJobStatus = { status: "fulfilled", id: job.id };
        state.currentStep = currentStep;
        state.status = status;
        state.job = job;
        state.drawer.isDrawerOpen = true;
      });
  },

  // FOR STEP 3 add reducers when click on build
});

export const {
  setStepOneCardDeleteLoader,
  nextStep,
  prevStep,
  setJobName,
  setIsPopupOpen,
  setIsDrawerOpen,
  clearStepper,
  setUploadingDisable,
  setBackDisable,
  setBuildsLoadingDisable,
  continueIncompleteJob,
  addCard,
  deleteCard,
  deleteEmptyCard,
  selectOption,
  addUploadedStlFiles,
  setErrors,
  addQuantity,
  deletePart,
  clearBuilds,
  clearUniqueParts,
  setFolderOpen,
  setJobCost,
  setSearchQuery,
  setUniqueParts,
} = stepperSlice.actions;

export default stepperSlice.reducer;

const errorMessageGenerator = (name, value) => {
  // console.log("name--", name, value);
  if (name === "layer_thickness" && value === "") {
    return `Please select an option for Layer Thickness`;
  }
  if (value === "") {
    return `Please select an option for ${name}`;
  }
  return "";
};

const findPartById = (materials, partId) => {
  for (let i = 0; i < materials.length; i++) {
    const parts = materials[i].parts;

    for (let j = 0; j < parts.length; j++) {
      if (parts[j].id === partId) {
        return parts[j];
      }
    }
  }

  return null; // Return null if part is not found
};

export const postJob = createAsyncThunk(
  "post/job",
  async (user_id, thunkAPI) => {
    const state = thunkAPI.getState();

    try {
      const statusValue = state.stepperSlice.currentStep === 3 ? true : false;

      // Perform save logic here
      const conditionalJobCost = state.stepperSlice?.jobCost
        ? state.stepperSlice.jobCost
        : 0;

      const res = await axios.put(
        "/api/v1/save",
        JSON.stringify({
          currentStep: state.stepperSlice.currentStep,
          status: statusValue,
          job: state.stepperSlice.job,
          jobCost: conditionalJobCost,
        }),

        {
          headers: { "Content-Type": "application/json" },
        },
      );

      const res1 = {
        id: state.stepperSlice.job.id,
        name: state.stepperSlice.job.job_name,
        step: state.stepperSlice.currentStep,
        status: statusValue,
        user_id: user_id,
        createdAt: "2023-05-31T07:28:25.000Z",
        updatedAt: "2023-05-31T07:28:25.000Z",
      };

      thunkAPI.dispatch(fetchJobs());
      thunkAPI.dispatch(clearStepper());
    } catch (err) {
      console.log(err);
      const { rejectWithValue } = thunkAPI;

      return rejectWithValue(err.response.data);
    }
  },
);

export const buildRes = createAsyncThunk("post/builds", async (_, thunkAPI) => {
  const state = thunkAPI.getState().stepperSlice;
  try {
    // Perform save logic here
    thunkAPI.dispatch(
      setBuildsLoadingDisable({ loading: true, hasError: false }),
    );
    thunkAPI.dispatch(setUploadingDisable(true));

    const response = await axios.post(
      "/api/v1/build",
      JSON.stringify({
        currentStep: state.currentStep,
        status: state.status,
        job: state.job,
      }),
      {
        headers: { "Content-Type": "application/json" },
      },
    );

    console.log(response.data.builds);

    thunkAPI.dispatch(
      setBuildsLoadingDisable({ loading: false, hasError: false }),
    );
    thunkAPI.dispatch(setUploadingDisable(false));
    return response.data.builds;
  } catch (err) {
    console.log("bulls error", err);
    const { rejectWithValue } = thunkAPI;
    thunkAPI.dispatch(
      setBuildsLoadingDisable({ loading: false, hasError: true }),
    );

    return rejectWithValue(err.response.data);
  }
});

export const showCompletedJob = createAsyncThunk(
  "get/completedJob",
  async (jobId, thunkAPI) => {
    //todo: API call here for completed jobs view to work
    try {
      const response = await axios.get(`/api/v1/job/completed/${jobId}`);
      console.log(response.data);

      return response.data;
    } catch (err) {
      console.error(err);
      const { rejectWithValue } = thunkAPI;

      return rejectWithValue(err.response.data);
    }
  },
);
