import React, { useCallback, useEffect, useRef, useState } from "react";
import "./sidebar.css";
import { Link, Outlet } from "react-router-dom";
import small_logo from "../../Assets/NewImages/logo_small.png";
import logo2 from "../../Assets/NewImages/logo_second.png";
import { CgProfile } from "react-icons/cg";
import {
	MdDashboard,
	MdSettings,
	MdPerson,
	MdMenu,
	MdExitToApp,
	MdOutlineClose,
	MdSpaceDashboard,
	MdOutlineArrowDropDown,
} from "react-icons/md";
import { useSelector } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { removeAllTokens } from "../../auth/auth.service";

export const Sidebar = () => {
	const [show, setShow] = useState(false);
	const [tab, setTab] = useState(0);
	const componentRef = useRef(null);
	const [activeClass, setActiveClass] = useState(null);
	const user = useSelector((state) => state.authSlice?.user);

	//Selecting the active class element
	const toggleActiveClass = (element) => {
		if (activeClass === null) {
			setActiveClass(element);
		} else if (activeClass === element) {
			setActiveClass(null);
		} else {
			setActiveClass(element);
		}
	};

	const handleLogout = () => {
		removeAllTokens();
		window.location.href = "https://intechadditive.us.auth0.com/v2/logout";
	};

	const handleDashboardRedirect = () => {
		window.location.href = "https://dev-dashboard.intechadditive.com";
	};

	//Remove active class if event is outside the component
	const toggleProfileDropdown = useCallback(
		(event) => {
			// Check whether the click target is within the component or not
			if (
				componentRef.current &&
				!componentRef.current.contains(event.target) &&
				activeClass
			) {
				setActiveClass(null);
			}
		},
		[componentRef, activeClass, toggleActiveClass]
	);

	const DropdownItem = ({ Icon, text, onClick }) => {
		return (
			<div
				className={`${"dropdownItem"} ${"listItem"}`}
				onClick={onClick}
			>
				<span style={{ paddingLeft: "0.8rem" }}>{<Icon />}</span>
				{text}
			</div>
		);
	};

	//Add event listner to remove active class
	useEffect(() => {
		// Add event listener to window object when the component mounts
		window.addEventListener("click", toggleProfileDropdown);

		// Remove event listener when the component unmounts
		return () => {
			window.removeEventListener("click", toggleProfileDropdown);
		};
	}, [toggleProfileDropdown]);

	return (
		<main className={show ? "space-toggle" : null}>
			<header className={`header ${show ? "space-toggle" : null}`}>
				<div
					className="header-toggle"
					onClick={() => setShow(!show)}
				>
					{show ? <MdOutlineClose size={25} /> : <MdMenu size={25} />}
				</div>

				<div
					className="profile"
					ref={componentRef}
				>
					<h3
						className={"userProfileContainer"}
						onClick={() => {
							toggleActiveClass("profile");
						}}
					>
						{user?.firstName[0].toUpperCase()}
					</h3>

					{activeClass === "profile" && (
						<div
							className={`${"profile-dropdown"} ${
								activeClass === "profile" ? "open" : ""
							}`}
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "1rem",
									padding: "1rem",
									textAlign: "center",
									fontWeight: "500",
									cursor: "default",
								}}
								// className="dropdown-menu"
							>
								<h3 className={"userProfileContainer"}>
									{user?.firstName[0].toUpperCase()}
								</h3>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "left",
										alignItems: "flex-start",
									}}
								>
									{user?.firstName}
									<p style={{ fontSize: "12px", opacity: "0.4" }}>
										{user?.email}
									</p>
								</div>
							</div>
							<hr
								color="lightgray"
								style={{ opacity: "0.4" }}
							/>
							<DropdownItem
								text="User Dashboard"
								Icon={() => <MdSpaceDashboard size={20} />}
								onClick={handleDashboardRedirect}
							/>
							<DropdownItem
								text="Logout"
								Icon={() => <MdExitToApp size={20} />}
								onClick={handleLogout}
							/>
						</div>
					)}
				</div>
			</header>

			<aside className={`sidebar ${show ? "show" : null}`}>
				<div
					style={{
						zIndex: "100",
						position: "absolute",
						right: "10px",
						top: "10px",
						color: "white",
					}}
					className="header-toggle responsive-toggle"
					onClick={() => setShow(!show)}
				>
					{show ? <MdOutlineClose size={25} /> : ""}
				</div>
				<nav className="nav">
					<div>
						<div className="nav-logo">
							{/* <i className={`fas fa-home-alt nav-logo-icon`}></i> */}
							{/* <span className="nav-logo-name">Homepage</span> */}
							<div className="close_nav fade-in-image">
								<img
									src={small_logo}
									alt="logo"
								/>
							</div>
							<div className={show ? "open_nav fade-in-image" : "close_nav"}>
								{show ? (
									<img
										src={logo2}
										alt="logo"
									/>
								) : (
									<div></div>
								)}
							</div>
						</div>

						<div className="nav-list">
							<Link
								to="/dashboard/home"
								className={` "nav-link "+ ${
									+tab === 0 ? "nav-link side-active" : "nav-link"
								}`}
								data-tooltip-id="dashboard-tooltip"
								data-tooltip-delay-show="1000"
								data-tooltip-content="Dashboard"
								data-place="right"
								onClick={() => {
									if (show) setShow(!show);
									setTab(0);
								}}
							>
								<MdDashboard size={20} />
								<span className="nav-link-name">Dashboard</span>
							</Link>
							<ReactTooltip
								id="dashboard-tooltip"
								place="right"
								style={{
									backgroundColor: "#454545",
									opacity: `${!show ? 1 : 0}`,
									fontSize: "12px",
								}}
							/>
							{/* <Link
                to="/dashboard/myparts"
                className={` "nav-link" ${
                  tab == 1 ? "nav-link side-active" : "nav-link"
                }`}
                onClick={() => setTab(1)}
              >
                <i className="fas fa-hotel nav-link-icon"></i>
                <span className="nav-link-name">My Parts</span>
              </Link> */}
							{/* <Link
                to="/dashboard/pastestimation"
                className={` "nav-link" ${
                  tab == 2 ? "nav-link side-active" : "nav-link"
                }`}
                onClick={() => setTab(2)}
              >
                <i className="fas fa-image nav-link-icon"></i>
                <span className="nav-link-name">Part Estimation</span>
              </Link> */}
							<Link
								to="/dashboard/settings"
								className={` "nav-link" ${
									tab == 3 ? "nav-link side-active" : "nav-link"
								}`}
								data-tooltip-id="settings-tooltip"
								data-tooltip-content="Settings"
								data-tooltip-delay-show="1000"
								data-place="right"
								onClick={() => {
									if (show) setShow(!show);
									setTab(3);
								}}
							>
								<MdSettings size={20} />
								<span className="nav-link-name">Settings</span>
							</Link>
							<ReactTooltip
								id="settings-tooltip"
								place="right"
								style={{
									backgroundColor: "#454545",
									opacity: `${!show ? 1 : 0}`,
									fontSize: "12px",
								}}
							/>
						</div>
					</div>

					{/* <Link to="/logout" className="nav-link">
            <i className="fas fa-sign-out nav-link-icon"></i>
            <span className="nav-link-name">Logout</span>
          </Link> */}
				</nav>
			</aside>

			{/* <h1>Content</h1> */}
			<Outlet />
		</main>
	);
};
