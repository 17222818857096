import { useState } from "react";
import "./tabs.css";
import { MdSearch } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setSearchQuery } from "../../Store/Slice/stepperSlice";
export function Tabs({ tabs }) {
	const [activeTab, setActiveTab] = useState(0);
	const completeSearchQuery = useSelector(
		(state) => state.stepperSlice.search.completedJobSearchQuery
	);
	const incompleteSearchQuery = useSelector(
		(state) => state.stepperSlice.search.incompleteJobSearchQuery
	);
	const dispatch = useDispatch();

	const handleSearchQuery = (e) => {
		// setSearchQuery(e.target.value);
		if (activeTab === 0) {
			dispatch(setSearchQuery({ complete: e.target.value, incomplete: "" }));
		} else {
			dispatch(setSearchQuery({ complete: "", incomplete: e.target.value }));
		}
	};

	return (
		<div>
			<div className="tab-list">
				<div>
					{tabs.map((tab, index) => (
						<button
							key={index}
							onClick={() => setActiveTab(index)}
							className={index === activeTab ? "active" : ""}
						>
							{tab.label}
						</button>
					))}
				</div>

				{/* SEARCH BAR */}
				<div className={"searchBar"}>
					<input
						type="text"
						placeholder="Search..."
						value={activeTab == 0 ? completeSearchQuery : incompleteSearchQuery} // Use local state
						onChange={handleSearchQuery}
					/>
					<MdSearch
						color="grey"
						size={20}
					/>
				</div>
			</div>
			{tabs[activeTab].content}
		</div>
	);
}
// function App() {
//   const tabs = [
//     {
//       label: "Tab 1",
//       content: <div>Tab 1 content</div>,
//     },
//     {
//       label: "Tab 2",
//       content: <div>Tab 2 content</div>,
//     },
//     {
//       label: "Tab 3",
//       content: <div>Tab 3 content</div>,
//     },
//   ];

//   return (
//     <div>
//       <Tabs tabs={tabs} />
//     </div>
//   );
// }
