import { useState } from "react";
import "./step1Card.css";
import {
  deleteFilesFromS3,
  multiPartUploadToS3,
  uploadFileToS3,
} from "../../AWS/awsService";
import { CurrencyConverter, trimPartName } from "../../Utils/utils";
import { Loader } from "../../Components/Loader/Loader";

//Library Imports
import { v4 as uuidv4 } from "uuid";
import { MdDelete, MdInfoOutline } from "react-icons/md";
import { Tooltip as ReactTooltip } from "react-tooltip";

//Redux Imports
import { useDispatch, useSelector } from "react-redux";
import {
  addUploadedStlFiles,
  deleteCard,
  selectOption,
  setErrors,
  setStepOneCardDeleteLoader,
  setUploadingDisable,
} from "../../Store/Slice/stepperSlice";

const Step1Card = (props) => {
  const materialsArray = [
    { powderCost: 0, value: "", text: "Select an option" },
    { powderCost: 4017.6, value: "AlSi10Mg", text: "AlSi10Mg" },
    { powderCost: 9606.38, value: "Cobalt Chrome", text: "Cobalt Chrome" },
    {
      powderCost: 4973.4,
      value: "Maraging Steel 300",
      text: "Maraging Steel 300",
    },
    {
      powderCost: 7445.5,
      value: "Nickel Alloy IN625",
      text: "Nickel Alloy IN625",
    },
    {
      powderCost: 7327.5,
      value: "Nickel Alloy IN718",
      text: "Nickel Alloy IN718",
    },
    { powderCost: 17464, value: "Titanium Ti64", text: "Titanium Ti64" },
    {
      powderCost: 2371.5,
      value: "Stainless Steel 17-4HP",
      text: "Stainless Steel 17-4HP",
    },
    {
      powderCost: 2371.5,
      value: "Stainless Steel 316L",
      text: "Stainless Steel 316L",
    },
    {
      powderCost: 8537.4,
      value: "Cu-Cr-Zr",
      text: "Cu-Cr-Zr",
    },
  ];

  const layerThicknessArray = [
    { value: "20", text: "20 µm" },
    { value: "30", text: "30 µm" },
    { value: "40", text: "40 µm" },
    { value: "50", text: "50 µm" },
    { value: "60", text: "60 µm" },
    { value: "70", text: "70 µm" },
    { value: "80", text: "80 µm" },
    { value: "90", text: "90 µm" },
    { value: "100", text: "100 µm" },
  ];

  const dispatch = useDispatch();

  const card = useSelector((state) => state.stepperSlice.job?.materials);
  const errors = useSelector((state) => state.stepperSlice.errors);
  const stepOneCardDeleteLoader = useSelector(
    (state) => state.stepperSlice.stepOneCardDeleteLoader,
  );
  const currencyType = useSelector((state) => state.authSlice?.currencyType);
  const userID = useSelector((state) => state.authSlice?.user?.id);

  //Single responsibilty function
  const handleLayerThicknessChange = (event) => {
    const { name, value } = event.target;

    dispatch(setErrors({ cardNumber: props.cardNumber, name, value }));

    handleOptionChange(props.id, event);
  };

  const handleMaterialChange = (event) => {
    const { name, value } = event.target;
    const powderCost =
      event.target.options[event.target.selectedIndex].getAttribute(
        "data-powder-cost",
      );
    console.log(powderCost);

    // Check if the selected material is already chosen in another card
    const isMaterialSelected = card.some((cardItem, index) => {
      return index !== props.cardNumber && cardItem.material === value;
    });

    if (isMaterialSelected) {
      const errorMessage = "Material already selected in another card";
      dispatch(
        setErrors({ cardNumber: props.cardNumber, name, value, errorMessage }),
      );
    } else {
      dispatch(setErrors({ cardNumber: props.cardNumber, name, value }));
    }

    handleOptionChange(props.id, event);

    //Converts currnecy in the job slice object
    // const currencySpecificPowderCost = (
    //   powderCost * CurrencyConverter()
    // ).toFixed(2);

    //Default powderCost is in INR hence conversion according to auth Currency Type
    handleInputChange({
      target: {
        name: "cost",
        value: CurrencyConverter("INR", currencyType, powderCost),
      },
    });
  };

  const handleOptionChange = (cardId, event) => {
    const { name, value } = event.target;
    dispatch(selectOption({ cardId, name, value }));
  };

  // handle cost input
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    // Validate if the value is a positive number
    // const isValid = /^\d+$/.test(value) && Number(value) >= 0;
    const isValid = /^(\d+(\.\d*)?)?$/.test(value) && Number(value) >= 0;
    const errorMessage = isValid ? "" : "Please enter a positive number";
    const cardNumber = props.cardNumber;

    dispatch(setErrors({ cardNumber, name, value, errorMessage }));

    if (isValid) {
      handleOptionChange(props.id, event);
    }
  };

  // upload s3 code start
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileNumber, setFileNumber] = useState({
    currentFileNumber: null,
    totalFileNumber: null,
  });
  const [complete, setComplete] = useState(true);

  const validateFiles = (files) => {
    const validFiles = [];
    for (const file of files) {
      const extension = file.name.split(".").pop().toLowerCase();

      if (extension === "stl" && file.size <= 3e8) {
        validFiles.push(file);
      } else {
        if (file.size > 3e8) {
          alert(`Error: File '${file.name}' is larger than 300 MB `);
        } else {
          alert(`Error: File '${file.name}' is not an .stl file.`);
        }
      }
    }

    //Removing files that have already been uploaded
    const parts = card[props.cardNumber].parts;
    const updatedValidFiles = validFiles.filter((file) => {
      return parts ? !parts.find((part) => part.name === file.name) : file;
    });

    return updatedValidFiles;
  };

  // upload s3 code end here
  const handleUpload = async (event) => {
    const file = validateFiles(event.target.files);

    if (file.length === 0 && event.target.files.length !== 0) {
      // If file already uploaded, show an error
      const errorMessage = "File already uploaded";
      const name = "file";

      dispatch(setErrors({ cardNumber: props.cardNumber, name, errorMessage }));
      return;
    } else if (file.length === 0) {
      // No file selected, show an error
      const errorMessage = "Please select a file";
      const name = "file";

      dispatch(setErrors({ cardNumber: props.cardNumber, name, errorMessage }));
      return;
    } else if (
      file.length +
        (card[props.cardNumber].parts != null
          ? card[props.cardNumber].parts.length
          : 0) >
      10
    ) {
      // Exceeded maximum file limit, show an error
      console.log("INside else if");
      const errorMessage = "Maximum 10 files can be selected";
      const name = "file";

      dispatch(setErrors({ cardNumber: props.cardNumber, name, errorMessage }));
      return;
    } else {
      const name = "file";
      const errorMessage = "";

      dispatch(setErrors({ cardNumber: props.cardNumber, name, errorMessage }));
    }

    const userCardId = props.id;

    setComplete(false);
    dispatch(setUploadingDisable(true));

    for (let i = 0; i < file.length; i++) {
      setSelectedFile(file[i].name);
      setFileNumber({
        currentFileNumber: i + 1,
        totalFileNumber: file.length,
      });

      try {
        //If file is smaller than 5MB then don't multipart upload
        if (file.size < 5 * 1024 * 1024) {
          const s3Url = await uploadFileToS3(
            file[i],
            props.jobId,
            props.id,
            setProgress,
            userID,
          );

          console.log(s3Url);
          setProgress(0);
        } else {
          const s3Url = await multiPartUploadToS3(
            file[i],
            props.jobId,
            props.id,
            setProgress,
            userID,
          );

          console.log(s3Url);
          setProgress(0);
        }

        const partObj = {
          name: file[i].name,
          id: uuidv4(),
          qty: 1,
        };

        dispatch(addUploadedStlFiles({ cardId: userCardId, partObj }));
      } catch (error) {
        console.log("Catch error", error);
      }
    }

    setComplete(true);
    dispatch(setUploadingDisable(false));
  };

  const handleCardDelete = async (id) => {
    const userCardId = id;

    dispatch(setStepOneCardDeleteLoader({ status: true, id }));

    await deleteFilesFromS3(props.jobId, userCardId, userID);

    dispatch(deleteCard({ id }));
    dispatch(setStepOneCardDeleteLoader({ status: false, id: null }));
  };

  // const CurrencyConverter = () => {
  //   switch (currencyType) {
  //     case "INR":
  //       return 1;
  //
  //     case "USD":
  //       return 0.012;
  //
  //     case "EUR":
  //       return 0.011;
  //
  //     case "YEN":
  //       return 1.77;
  //
  //     default:
  //       return 1;
  //   }
  // };

  return (
    <div className="step1-card">
      {stepOneCardDeleteLoader.status &&
      stepOneCardDeleteLoader.id === props.id ? (
        <div
          style={{
            height: "36.3vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div
            className="delete-btn"
            onClick={() => handleCardDelete(props.id)}
          >
            <MdDelete size={30} />
          </div>

          <div className="cardNumber">
            <p>{props.cardNumber + 1}</p>
          </div>

          <div>
            <p>Material</p>

            <select
              className="select-btn"
              name="material"
              value={card[props.cardNumber].material}
              onChange={(event) => handleMaterialChange(event)}
            >
              {materialsArray.map((material) => (
                <option
                  value={material.value}
                  data-powder-cost={material.powderCost}
                >
                  {material.text}
                </option>
              ))}
            </select>

            {errors[props.cardNumber]?.material && (
              <div className="error-message">
                {errors[props.cardNumber]?.material}
              </div>
            )}
          </div>

          <div className="part-select-div">
            <div>
              <p>Powder cost per Kg</p>

              <input
                className="input-field "
                type="number"
                name="cost"
                value={+card[props.cardNumber].cost}
                min={0}
                step={0.01}
                placeholder="Enter Cost"
                onChange={(event) => handleInputChange(event)}
              />

              {errors[props.cardNumber]?.cost && (
                <div className="error-message">
                  {errors[props.cardNumber]?.cost}
                </div>
              )}
            </div>

            <div>
              <p style={{ paddingLeft: "7px" }}>Currency Type</p>

              <p
                className="select-btn"
                style={{
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  fontSize: "x-large",
                  backgroundColor: "#eef6f0",
                  fontWeight: "bold",
                  color: "#60ad57",
                }}
              >
                {currencyType}
              </p>
            </div>
          </div>

          <div>
            <p>Layer Thickness</p>

            <select
              className="select-btn"
              name="layer_thickness"
              value={card[props.cardNumber].layer_thickness}
              onChange={(event) => handleLayerThicknessChange(event)}
            >
              <option value="">Select an option</option>
              {layerThicknessArray.map((layerThickness) => (
                <option value={layerThickness.value}>
                  {layerThickness.text}
                </option>
              ))}
            </select>

            {errors[props.cardNumber]?.layer_thickness && (
              <div className="error-message">
                {errors[props.cardNumber]?.layer_thickness}
              </div>
            )}
          </div>

          <div
            className="choosefile"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <input type="file" accept=".stl" onChange={handleUpload} multiple />

            {/* Tooltip Starts */}
            <div
              data-tooltip-id="card-target"
              data-tooltip-content="Please upload the part in MM with size limit of 300MB  (Maximum 10 parts allowed) "
              data-tooltip-delay-show="150"
            >
              <MdInfoOutline size={22} />

              <ReactTooltip
                id="card-target"
                place="left"
                style={{
                  width: "15rem",
                }}
              />
            </div>
            {/* Tooltip Ends */}
          </div>

          {errors[props.cardNumber]?.file && (
            <div className="error-message">
              {errors[props.cardNumber]?.file}
            </div>
          )}

          <div className={`my-div ${complete ? "green" : ""}`}>
            {progress !== 0 && (
              <div className="progressBar">
                <div
                  className="progress"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            )}

            {complete
              ? fileNumber.currentFileNumber == null
                ? ""
                : `Complete! Uploaded Files - ${fileNumber.currentFileNumber}/${fileNumber.totalFileNumber}`
              : `${trimPartName(selectedFile) + " - " + progress}%`}

            <p>
              {complete
                ? ``
                : `Uploading - ${fileNumber.currentFileNumber}
            /
            ${fileNumber.totalFileNumber}`}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default Step1Card;
