import "./stepper.css";

//Redux Imports
import { useDispatch, useSelector } from "react-redux";
import {
  clearBuilds,
  clearStepper,
  clearUniqueParts,
  nextStep,
  postJob,
  prevStep,
  setErrors,
  setIsDrawerOpen,
  setUploadingDisable,
} from "../../Store/Slice/stepperSlice";

export const Stepper = () => {
  // REDUX CODE HERE
  const dispatch = useDispatch();

  const activeStep = useSelector((state) => state.stepperSlice.currentStep);
  const status = useSelector((state) => state.stepperSlice.status);
  const cards = useSelector((state) => state.stepperSlice?.job.materials);
  const errors = useSelector((state) => state.stepperSlice.errors);
  const joberror = useSelector((state) => state.stepperSlice.jobNameError);
  const backButtonDisable = useSelector(
    (state) => state.stepperSlice.backButtonDisable,
  );
  const jobErrName = useSelector((state) => state.stepperSlice);
  const uploadingDisable = useSelector(
    (state) => state.stepperSlice.uploadingDisable,
  );

  const validateStep1 = () => {
    if (!jobErrName.hasOwnProperty("jobNameError")) {
      dispatch(
        setErrors({
          cardNumber: -1, // Assuming the job name error is not related to a specific card
          name: "jobName",
          errorMessage: "Job Name can't be blank",
        }),
      );

      return false;
    }

    if (errors.length === 0 || cards.length === 0 || joberror) {
      alert("Please add atleast one material to move forward");

      return false;
    }

    const keysToCheck = [
      "material",
      "cost",
      // "currency",
      "layer_thickness",
      "file",
    ]; // Define the keys you want to check

    let hasErrors = false;

    for (let i = 0; i < cards.length; i++) {
      const cardErrorObj = errors[i];

      for (const key of keysToCheck) {
        console.log("hello 2");
        if (!cardErrorObj.hasOwnProperty(key)) {
          // Dispatch the error action for missing key
          if (key === "cost") {
            dispatch(
              setErrors({
                cardNumber: i,
                name: key,
                errorMessage: "Please enter a positive number",
              }),
            );
          } else if (key === "file") {
            console.log("inside else");

            dispatch(
              setErrors({
                cardNumber: i,
                name: key,
                errorMessage: "Please select a file",
              }),
            );
          } else {
            dispatch(
              setErrors({
                cardNumber: i,
                name: key,
                value: "",
                // errorMessage: "Error message for missing key",
              }),
            );
          }

          hasErrors = true;
          // return false; // Return false if any key is missing
        } else if (cardErrorObj[key] !== "") {
          let flag = false;

          if (key === "file") {
            if (cards[i].parts.length <= 10 && cards[i].parts.length > 0) {
              console.log("inside if");
              dispatch(
                setErrors({
                  cardNumber: i,
                  name: key,
                  errorMessage: "",
                }),
              );

              hasErrors = false;
              flag = true;
            }
          }

          if (!flag) hasErrors = true;
        }
      }
    }

    return !hasErrors; // Return true if all keys are present
  };

  const handleNext = () => {
    if (uploadingDisable === true) return;

    if (activeStep === 0) {
      if (validateStep1()) dispatch(nextStep());
      else return;
    } else if (activeStep === 1) {
      dispatch(nextStep());

      return;
    } else if (activeStep === 2) {
      dispatch(nextStep());
    } else if (activeStep === 3 && status == false) {
      dispatch(postJob());

      dispatch(setIsDrawerOpen(false));
    } else if (activeStep === 3 && status == true) {
      dispatch(setIsDrawerOpen(false));

      dispatch(clearStepper());
    }
  };

  const handleBack = () => {
    if (activeStep === 2) {
      dispatch(clearBuilds());

      dispatch(clearUniqueParts());
    }

    if (uploadingDisable === true) dispatch(setUploadingDisable(false));
    dispatch(prevStep());
  };

  return (
    <div className="stepper-container">
      <div className="stepper">
        <div className={`step ${activeStep >= 0 ? "active" : ""}`}>
          <div className="step-number">1</div>
          {/* <div className="step-label">Step One</div> */}
          <div className="step-label">Create Job</div>
        </div>

        <hr className="divider" />

        <div className={`step ${activeStep >= 1 ? "active" : ""}`}>
          <div className="step-number">2</div>
          {/* <div className="step-label">Step Two</div> */}
          <div className="step-label">Add Quantity</div>
        </div>

        <hr className="divider" />

        <div className={`step ${activeStep >= 2 ? "active" : ""}`}>
          <div className="step-number">3</div>
          {/* <div className="step-label">Step Three</div> */}
          <div className="step-label">Review </div>
        </div>

        <hr className="divider" />

        <div className={`step ${activeStep >= 3 ? "active" : ""}`}>
          <div className="step-number">4</div>
          {/* <div className="step-label">Step Four</div> */}
          <div className="step-label">Invoice </div>
        </div>
      </div>

      <div className="stepper-button-container">
        <div className="step-btn">
          {activeStep !== 3 && (
            <button
              className={`${backButtonDisable ? "next-inactive" : ""}`}
              disabled={activeStep === 0 || backButtonDisable == true}
              onClick={handleBack}
            >
              Back
            </button>
          )}

          {/* <button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </button> */}
        </div>

        <div className="step-btn">
          <button
            onClick={handleNext}
            className={`${uploadingDisable ? "next-inactive" : ""}`}
          >
            {/* Next */}
            {/* {activeStep !== 3 ? "Next" : "Save"} */}
            {activeStep > 2 ? (status == true ? "Close" : "Save") : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};
