import {
	MdAdd,
	MdClose,
	MdDelete,
	MdInfoOutline,
	MdModeEdit,
} from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
//Redux Imports
import { useSelector, useDispatch } from "react-redux";
import { Popup } from "Components";
import {
	addMachine,
	editMachine,
	openModal,
	closeModal,
	resetForm,
	updateFormData,
	setFormErrors,
	getMachines,
	setForm,
	deleteMachine,
} from "../../../Store/Slice/settingsSlice.js";
import { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

const MachineType = () => {
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedMachine, setSelectedMachine] = useState(null);

	//Redux imports
	const dispatch = useDispatch();
	const machines = useSelector((state) => state.settingsSlice?.machines);
	const showModal = useSelector((state) => state.settingsSlice?.showModal);
	const formData = useSelector((state) => state.settingsSlice?.formData);
	const formErrors = useSelector((state) => state.settingsSlice?.formErrors);
	const isEditing = useSelector((state) => state.settingsSlice?.isEditing);
	const userID = useSelector((state) => state.authSlice.user?.id);
	const currencyType = useSelector((state) => state.authSlice.currencyType);

	const getCurrencySymbol = (code) => {
		const currencySymbols = {
			USD: "$",
			INR: "₹",
			EUR: "€",
			YEN: "¥",
		};
		return currencySymbols[code] || "";
	};

	const validateForm = () => {
		const errors = {};

		const requiredFields = ["machineName", "x", "y", "z", "mhr", "numLasers"];

		requiredFields.forEach((field) => {
			if (formData[field].trim() === "") {
				errors[field] = `${field} is required`;
			}
		});

		dispatch(setFormErrors(errors));
		dispatch(setFormErrors(errors));

		return Object.keys(errors).length === 0;
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (validateForm()) {
			const machine = { ...formData, id: uuidv4(), userID };

			if (isEditing === true) {
				dispatch(editMachine(formData));
			} else {
				dispatch(addMachine(machine));
			}
		}
	};

	const handleSingleDelete = (index) => {
		setShowDeleteModal(true);
		setSelectedMachine({ index });
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		dispatch(updateFormData({ name, value }));
	};

	const handleOpenModal = () => {
		dispatch(openModal());
	};

	const handleCloseModal = () => {
		dispatch(closeModal());
	};

	const handleResetForm = () => {
		dispatch(resetForm());
	};

	const handleEdit = (id) => {
		dispatch(setForm(id));
		handleOpenModal();
	};

	const onClosePopUp = () => {
		setShowDeleteModal(false);
	};

	const EditMachineModal = () => (
		<div className="modal">
			<div className="settings-modal-content">
				<button onClick={handleCloseModal} className="settings-cross-btn">
					<MdClose size={20} />
				</button>

				<h2>{isEditing ? "Edit Machine" : "Add New Machine"}</h2>

				<form onSubmit={handleSubmit}>
					<label>
						Machine:
						<input
							type="text"
							name="machineName"
							placeholder="Enter Machine Name"
							value={formData.machineName}
							onChange={handleChange}
							className={formErrors.machineName ? "input-error" : ""}
						/>
						{formErrors.machineName && (
							<span className="error-message">{formErrors.machineName}</span>
						)}
					</label>

					<label>
						X: Length
						<input
							type="number"
							name="x"
							placeholder="Enter Length of Base Plate"
							value={formData.x}
							onChange={handleChange}
							className={formErrors.x ? "input-error" : ""}
						/>
						{formErrors.x && (
							<span className="error-message">{formErrors.x}</span>
						)}
					</label>

					<label>
						Y: Width
						<input
							type="number"
							name="y"
							value={formData.y}
							placeholder="Enter Width of base Plate"
							onChange={handleChange}
							className={formErrors.y ? "input-error" : ""}
						/>
						{formErrors.y && (
							<span className="error-message">{formErrors.y}</span>
						)}
					</label>

					<label>
						Z: Height
						<input
							type="number"
							name="z"
							value={formData.z}
							placeholder="Enter Heigth of Base Plate"
							onChange={handleChange}
							className={formErrors.z ? "input-error" : ""}
						/>
						{formErrors.z && (
							<span className="error-message">{formErrors.z}</span>
						)}
					</label>

					<label>
						MHR (Machine Hour Rate):
						<div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
							<span
								style={{
									display: "flex",
									alignItems: "center",
									outline: "1px solid #d8d8d8",
									background: "#eef6f0",
									color: "green",
									padding: "0 10px",
									margin: "2px 0",
									borderRadius: "5px",
								}}
							>
								{getCurrencySymbol(currencyType)}
							</span>

							<input
								type="number"
								name="mhr"
								value={formData.mhr}
								placeholder={`Enter Machine Hour Rate in ${currencyType}`}
								onChange={handleChange}
								className={formErrors.mhr ? "input-error" : ""}
							/>
						</div>
						{formErrors.mhr && (
							<span className="error-message">{formErrors.mhr}</span>
						)}
					</label>

					<label>
						No of Lasers:
						<input
							type="number"
							name="numLasers"
							value={formData.numLasers}
							placeholder="Enter Number of Lasers"
							onChange={handleChange}
							className={formErrors.numLasers ? "input-error" : ""}
						/>
						{formErrors.numLasers && (
							<span className="error-message">{formErrors.numLasers}</span>
						)}
					</label>

					{/* Add more form fields for other machine details */}
					<div className="settings-form-btn">
						<button type="submit">{isEditing ? "Save" : "Add Machine"}</button>

						<button type="button" onClick={handleCloseModal}>
							Cancel
						</button>
					</div>
				</form>
			</div>
		</div>
	);

	useEffect(() => {
		//Get Machines API Call
		dispatch(getMachines());
	}, []);
	return (
		<div>
			<button className="add-machine-button" onClick={handleOpenModal}>
				<MdAdd size={20} />
				Add New Machine
			</button>
			<table className="machine-table">
				<thead>
					<tr>
						<th>S.No</th>
						<th>Machine Name</th>
						<th>X</th>
						<th>Y</th>
						<th>Z</th>
						<th
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							MHR
							{/* Mhr Tooltip */}
							<MdInfoOutline
								size={15}
								data-tooltip-id="mhr-target"
								data-tooltip-content="MHR indicates Machine hour rate and is the cost to operate the machine per hour.Please include capital and operating cost of the machine in the above currency type. "
								data-tooltip-delay-show="150"
							/>
							<ReactTooltip
								id="mhr-target"
								place="top"
								style={{
									width: "25rem",
									fontWeight: "500",
								}}
							/>
						</th>
						<th>No of Lasers</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{machines &&
						machines.map((machine, index) => (
							<tr key={machine.id}>
								<td>{index + 1}</td>
								<td>{machine.machineName}</td>
								<td>{machine.x}</td>
								<td>{machine.y}</td>
								<td>{machine.z}</td>
								<td>{machine.mhr}</td>
								<td>{machine.numLasers}</td>
								<td className="actions">
									<div
										className="edit-button"
										onClick={() => handleEdit(machine.id)}
									>
										<MdModeEdit fontSize={"1.2rem"} />
									</div>
									<div
										className="delete-button"
										onClick={() => handleSingleDelete(machine.id)}
									>
										<MdDelete fontSize={"1.2rem"} />
									</div>
								</td>
							</tr>
						))}
				</tbody>
			</table>

			{showModal && EditMachineModal()}

			{showDeleteModal && (
				<Popup
					onClose={onClosePopUp}
					handleSave={() => {
						dispatch(deleteMachine(selectedMachine.index));
						setShowDeleteModal(false);
					}}
					handleCancel={() => setShowDeleteModal(false)}
					triggeredBy={"settings"}
				/>
			)}
		</div>
	);
};

export default MachineType;
