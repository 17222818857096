import axios from "axios";
import Cookies from "universal-cookie";
import { refreshToken } from "../auth/auth.service";

// const BASE_URL = "http://localhost:8008";
const BASE_URL = "http://192.168.1.115:8008/";
// const BASE_URL = "http://192.168.1.141:8008/";
// const BASE_URL = "http://192.168.0.26:8008/";
const cookies = new Cookies();
// export default axios.create({
// 	baseURL: BASE_URL,
// 	headers: {
// 		Authorization: `Bearer ${cookies.get("access_token")}`,
// 	},
// })

const getAccessTokenFromCache = () => {
	return cookies.get("access_token");
};

const myAxios = axios.create({
	baseURL: BASE_URL,
	headers: {
		Authorization: `Bearer ${getAccessTokenFromCache()}`,
	},
});

myAxios.interceptors.response.use(
	async (response) => {
		if (response.status === 401) {
			await refreshToken();
		}
		return response;
	},
	async (error) => {
		// checking for token refresh
		if (
			["TOKEN_EXPIRED"].includes(error.code) ||
			error.message.includes("Token is expired")
		) {
			await refreshToken();
		}
		if (error.response && error.response.data) {
			return Promise.reject(error.response.data);
		}
		return Promise.reject(error.message);
	}
);

export default myAxios;
