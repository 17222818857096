import Step1 from "./steps/Step1/step1";
import Step2 from "./steps/Step2/step2";
import Step3 from "./steps/Step3/Step3";
import Step4 from "./steps/Step4/step4";
import { Stepper } from "./Components";

//Library Imports
import { MdClose } from "react-icons/md";

//Redux Imports
import { useSelector } from "react-redux";

const Drawer = (props) => {
  const currentStep = useSelector((state) => state.stepperSlice.currentStep);
  const buildsLoading = useSelector(
    (state) => state.stepperSlice.buildsLoadingDisable,
  );

  let componentToRender;

  switch (currentStep) {
    case 0:
      componentToRender = <Step1 />;
      break;

    case 1:
      componentToRender = <Step2 />;
      break;

    case 2:
      componentToRender = <Step3 />;
      break;

    case 3:
      componentToRender = <Step4 />;
      break;

    default:
      componentToRender = null;
      break;
  }

  return (
    <div className="drawer-container">
      <div className={props.isDrawerOpen ? "backdrop" : ""}></div>

      <div className={`drawer ${props.isDrawerOpen ? "open" : "close"}`}>
        <button className="drawer-navbar">
          {currentStep !== 3 && buildsLoading.loading === false && (
            <MdClose
              className="close-drawer-btn"
              onClick={props.handleToggle}
            />
          )}
        </button>

        <div className="drawer-content">{componentToRender}</div>

        <div className="stepper-parent-container">
          <Stepper />
        </div>
      </div>
    </div>
  );
};
export default Drawer;
