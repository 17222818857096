import { configureStore } from "@reduxjs/toolkit";
import { composeWithDevTools } from "@redux-devtools/extension";
import stepperSlice from "./Slice/stepperSlice";
import authSlice from "./Slice/authSlice";
import AuthMiddleware from "./authMiddleware";
import homeSlice from "./Slice/homeSlice";
import settingsSlice from "./Slice/settingsSlice";

const configureAppStore = () => {
  const isDevelopment = process.env.NODE_ENV === "development";

  const store = configureStore({
    reducer: {
      stepperSlice,
      settingsSlice,
      authSlice,
      homeSlice,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(AuthMiddleware),
    devTools: isDevelopment ? composeWithDevTools() : false,
  });

  return store;
};

export default configureAppStore;
